import { Box, SimpleGrid } from "@chakra-ui/react";
import { BaseHit } from "instantsearch.js";
import { UseHitsProps, useHits } from "react-instantsearch";

export interface HitsProps<T extends BaseHit> extends UseHitsProps<T> { 
  hitComponent?: any
}
export function Hits<T extends BaseHit>({
  hitComponent,
  ...props
}: HitsProps<T>) {
  const { hits, sendEvent } = useHits<T>(props);
  const HitComponent = (hitComponent as any)
  return (

    <SimpleGrid minChildWidth='250px' spacing={3}>
      {hits.map((hit) => (
        <Box
          display={'flex'}
          key={hit.objectID || (hit.id as string)}
          onClick={() => sendEvent("click", hit, "Hit Clicked")}
          onAuxClick={() => sendEvent("click", hit, "Hit Clicked")}
        >
          <HitComponent hit={hit} />
        </Box>
      ))}
    </SimpleGrid>
  );
}

