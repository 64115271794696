
import { getMission, getMissionKey, getMissionQuery } from '@/api/mission';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { MissionAttributesMeta, MissionWithEquipmentsAttributes } from '@/interfaces/mission';
import { ApiResourceResponse } from '@/utils/api';
import { Box, Button, Divider, HStack, Heading, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { QueryClient } from '@tanstack/react-query';
import { Link, LoaderFunction, useLoaderData, useParams } from 'react-router-dom';
import { AsyncVehiculeCard } from '@/screens/VehiculeCard/AsyncVehiculeCard';
import { parseAndFormatDateShort } from '@/utils/datesFormat';
import { TbCalendar, TbPlus, TbTag, TbUser, TbUserPlus } from 'react-icons/tb';
import { MdOutlineLocationOn, MdShortText } from 'react-icons/md';
import ValueWithLabel from '@/components/ValueWithLabel/ValueWithLabel';
import { MISSION_CODES } from '@/constants/mission';
import { MissionUserLineProfile } from '@/components/UserProfile/MissionUserLineProfile';
import { missionEditRoute } from '@/constants/routes';
import EmptyList from '@/components/EmptyList/EmptyList';
import AddMissionUserModal from '@/screens/AddMissionUserModal/AddMissionUserModal';
import { useOrganizations } from '@/contexts/useOrganizationsContext';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getMissionQuery(params.missionId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getMissionQuery(params.missionId as RecordId))
    } 
    return data
  }
)

export const MissionSummary = () => {

  const AVATAR_COUNT = 5
  const disclo = useDisclosure()
  const { ability } = useOrganizations()

  const addParticipantDisclo = useDisclosure()
  const { orgId, missionId } = useParams()

  const initialData = useLoaderData() as ApiResourceResponse<MissionWithEquipmentsAttributes, unknown, MissionAttributesMeta>;

  const { data: mission } = useNanuqQuery([getMissionKey, missionId], () => missionId ? getMission(missionId) : null, { initialData })

  if (!mission) {
    return <></>
  }

  return (
    <VStack spacing={0} alignItems={'stretch'}>
      <HStack flexDirection={{ base: 'column', lg: 'row' }} justify={'flex-start'} alignContent={'stretch'} flexWrap={'wrap'} alignItems={{ base: 'stretch', lg: 'flex-start' }}>
        <VStack
          p={[3, 3, 6]}
          spacing={3}
          alignItems={'stretch'}
          flex={1}
          borderRight={{ base: '0px', lg: '1px solid' }}
          borderRightColor={{ base: 'transparent', lg: 'border.default' }}
        >
          <Heading size={'xs'}>Détails</Heading>
          <VStack spacing={4} flex={1} alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'stretch'} key={missionId}>
            <ValueWithLabel icon={<TbCalendar />} label='Dates'>
              <Text>Du {parseAndFormatDateShort(mission?.data.attributes.begin_at)}</Text>
              <Text>au {parseAndFormatDateShort(mission?.data.attributes.end_at)}</Text>
            </ValueWithLabel>
            <ValueWithLabel icon={<TbUser />} label='Responsable'>
              <Text>{mission?.data.meta?.user.full_name}</Text>
            </ValueWithLabel>
            <ValueWithLabel icon={<TbTag />} label='Catégorie'>
              <Text>{MISSION_CODES[mission?.data.attributes.mission_type] || 'Inconnue'}</Text>
            </ValueWithLabel>
            <ValueWithLabel icon={<MdOutlineLocationOn />} label='Structure'>
              <Text>{mission?.data?.meta?.structure?.short_name}</Text>
            </ValueWithLabel>
          </VStack>
        </VStack>
        <VStack p={[3, 3, 6]} spacing={3} alignItems={'stretch'} flex={1}>
          <Heading size={'xs'}>Participants</Heading>

          <EmptyList list={mission.data.attributes.mission_users}>
            Aucun participant pour cette mission
          </EmptyList>
          {mission.data.attributes.mission_users.slice(0, disclo.isOpen ? 10000 : AVATAR_COUNT).map((mu) => {
            return (<Box key={mu.id}>{mu?.user && <MissionUserLineProfile role={mu.role} {...mu.user} />}</Box>)
          })}
          {mission.data.attributes.mission_users.length > AVATAR_COUNT && !disclo.isOpen && <Text onClick={disclo.onOpen} color={'fg.subtle'} size={'sm'}>
            ...et {mission.data.attributes.mission_users.length - AVATAR_COUNT} de plus
          </Text>}
          {disclo.isOpen && <Text onClick={disclo.onClose} color={'fg.subtle'} size={'sm'}>
            Réduire
          </Text>}
          {/* <Button as={Link} variant={'ghost'} to={missionEditRoute(orgId, mission.data.id)} leftIcon={<TbUserPlus />}>Ajouter un participant</Button> */}
          {ability?.can('update', 'mission', mission.data.attributes) && <Button onClick={addParticipantDisclo.onOpen} variant={'ghost'} leftIcon={<TbUserPlus />}>Ajouter un participant</Button>}
          <AddMissionUserModal orgId={orgId || ''} missionId={mission.data.id} isOpen={addParticipantDisclo.isOpen} onClose={addParticipantDisclo.onClose} />
        </VStack>
      </HStack>

      <VStack alignItems={'stretch'} flex={1}>
        {mission.data?.attributes?.content?.length && <>
          <Divider />
          <VStack spacing={3} p={[3, 3, 6]} alignItems={'stretch'} flex={1}>
            <ValueWithLabel icon={<MdShortText />} label='Résumé'>
              {(mission.data.attributes.content || "").split("\n").map(e => <Text key={e}>{e}</Text>)}
            </ValueWithLabel>
          </VStack>
        </>}
        <Divider />
        <VStack spacing={3} p={[3, 3, 6]} alignItems={'stretch'} flex={1}>
        <Heading size={'xs'}>Réservations</Heading>

        <EmptyList list={mission.data.attributes.mission_equipments}>
          Aucun véhicule réservé pour cette mission
        </EmptyList>

        {mission.data.attributes.mission_equipments?.map((equipment) => {
          return (
            <AsyncVehiculeCard orgId={orgId || ''} equipmentId={equipment.equipment_id} partialVehicule={equipment.equipment} key={equipment.equipment_id} />)
        })}
        {ability?.can('update', 'mission', mission.data.attributes) && <Button as={Link} variant={'ghost'} to={missionEditRoute(orgId, mission.data.id)} leftIcon={<TbPlus />}>Ajouter un véhicule</Button>}

        </VStack>

      </VStack>

      {/* {mission.data.attributes.requirements.length === 0 && (
        <EmptyList actionLink={missionEditRoute(orgId, missionId)} actionText='Configurer'>
          Aucun matériel requis
        </EmptyList>
      )} */}
    </VStack>
  );
}

export default MissionSummary;