
import { Box, CircularProgress, CircularProgressLabel, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';



export interface SimpleMissionFormProps {
  active: boolean,
  type: 'start' | 'end',
  progress: number,
  error?: string | undefined
}


export const TakeoutUploadProgress = ({ active, progress, error, type = 'start' }: SimpleMissionFormProps) => {

  const currentProgress = Math.floor(progress * 100)
  const progressColor = (currentProgress < 100 ? 'red.400' : 'green.400')
  
  const messageForProgress = useMemo(() => {
    if (currentProgress < 10) {
      return type == 'start' ? 'Préparation de la réservation' : 'Cloture du trajet'
    }
    else if (currentProgress < 75) {
      return 'Téléchargement des images'
    }
    else if (currentProgress < 85) {
      return 'Validation des kilométrages'
    }
    else if (currentProgress < 95) {
      return 'Remise au propre du planning'
    }
    else if (currentProgress <= 100) {
      return 'Finalisation...'
    }
  }, [currentProgress, type])

  return (<>
    {active && <VStack
      borderRadius={6}
      // borderColor={'bg.accent'}
      // borderWidth={1}
      px={[4, 6]}
      py={3}
      flexGrow={1}
      flexShrink={0}
      flexBasis={{ base: '100%', md: '250px' }}
      spacing={6}
      alignItems={'stretch'}
      justify={'center'}
      textAlign={'center'}
      // bg={'bg.subtle'}
      >
      <Box>
        <Text>{messageForProgress}</Text>
        <CircularProgress color={progressColor} value={currentProgress} size='120px'>
          <CircularProgressLabel>{currentProgress}%</CircularProgressLabel>
        </CircularProgress>
      </Box>
    </VStack>}
    </>
  );
}
