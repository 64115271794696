// import "instantsearch.css/themes/algolia-min.css";

import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';

import { vehiculesRoute } from "@/constants/routes";
import { TbArrowLeft, TbCalendarCancel, TbClearAll, TbClearFormatting } from 'react-icons/tb';
import { VehiculesListQueryParams } from './VehiculesList';
import { VehiculesListActiveFilters } from './VehiculesListActiveFilters';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiCollectionResponse } from '@/utils/api';
import { VehiculesListFiltersModal } from './VehiculesListFiltersModal';
import { AiOutlineClear } from "react-icons/ai";
import { useMemo } from 'react';


export interface VehiculesListToolbarProps {
  params?: VehiculesListQueryParams
  isLoading?:boolean
  data?: ApiCollectionResponse<EquipmentAttributes, any, EquipmentAttributesMeta> | null
}
export const VehiculesListToolbar = ({
  params,
  isLoading,
  data
}: VehiculesListToolbarProps) => {

  const { orgId } = useParams()
  const navigate = useNavigate()

  const onReset = () => {
    navigate(vehiculesRoute(orgId))
  }
  const paramsLength = useMemo(() => params ? Object.keys(params).length : 0, [params])

  return (
    <VStack spacing={3} mt={3} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
      <HStack alignContent={'flex-start'} alignItems={'center'} flexWrap='wrap' justifyContent={'space-between'}>
        <VehiculesListActiveFilters params={params} />
        <Text display={['none', 'none', 'block']}>{data?.meta?.count || '0'} véhicules</Text>

        <HStack>
          <Button size={'sm'} variant='link' isDisabled={paramsLength <= 2} leftIcon={<AiOutlineClear />} onClick={onReset}>Effacer les filtres</Button>
          <VehiculesListFiltersModal params={params} />
        </HStack>
      </HStack>

      <Divider />

    </VStack>
  );
}
