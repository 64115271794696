import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  StackDivider,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { OrganizationAttributes } from '@/interfaces/organization';

export interface OrganizationEditFormProps {
  organization: OrganizationAttributes,
  onSubmit: (values: Partial<OrganizationAttributes>, helpers: FormikHelpers<Partial<OrganizationAttributes>>) => Promise<any>
}

const OrganizationEditForm = ({ organization, onSubmit }: OrganizationEditFormProps) => {

  const initialValues = {
    name: organization.name,
    url: organization.url,
    domain: organization.domain,
  }

  return (<Formik<Partial<OrganizationAttributes>>
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {(props) => (
      <Form>

        <Stack spacing="5" divider={<StackDivider />}>
          <Field name='name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.name && form.touched.name ? true : false}>

                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                  alignItems="center"
                >
                  <FormLabel htmlFor='name'>Organization</FormLabel>
                  <Input {...field} maxW={{ md: '2xl' }} id='name' type='text' placeholder='Nom' />
                  <FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          </Field>
          <Field name='url'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.url && form.touched.url ? true : false}>

                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                  alignItems="center"
                >
                  <FormLabel htmlFor='url'>Site web</FormLabel>
                  <Input {...field} maxW={{ md: '2xl' }} id='url' type='text' placeholder='Site web' />
                  <FormErrorMessage>{form.errors.url as string}</FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          </Field>
          <Field name='domain'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.domain && form.touched.domain ? true : false}>

                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                  alignItems="center"
                >
                  <FormLabel htmlFor='domain'>Domaine autorisé</FormLabel>
                  <Input {...field} maxW={{ md: '2xl' }} id='domain' type='text' placeholder='Domaine' />
                  <FormErrorMessage>{form.errors.domain as string}</FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          </Field>

          {(props.dirty || props.submitCount) && (
            <Button
              variant="primary"
              isLoading={props.isSubmitting}
              disabled={!props.dirty}
              type='submit'
            >
              {props.dirty ? 'Mettre à jour' : 'Mis à jour'}
            </Button>)
          }
        </Stack>
      </Form>
    )}
  </Formik>
  )
}

export default OrganizationEditForm