import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  HStack,
  FormHelperText,
  Checkbox,
  Text,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { RecordId } from '@/interfaces/id';
import { addHours, format, isBefore, parseISO, startOfHour } from 'date-fns';
import { Select } from 'chakra-react-select';
import { toLocalDateTimeFormat } from '@/utils/datesFormat';
import { useUser } from '@/hooks/auth';
import { TbSearch } from 'react-icons/tb';
import { useMemo } from 'react';
import Loader from '@/components/Loader/Loader';
import { VehiculesListQueryParams } from '@/pages/Vehicules/VehiculesList';

export interface SimpleMissionFormProps {
  params?: VehiculesListQueryParams
  structureId?: RecordId,
  onSearch: (params: VehiculesListQueryParams) => void
  onReset?: () => void
}

const ReservationSearchForm = ({ params, structureId, onSearch, onReset }: SimpleMissionFormProps) => {

  const { data: user } = useUser()

  const userPreferedStructure = user?.meta.user_structures?.filter(e => e.role !== 'member')?.at(0) ??
                                user?.meta.user_structures?.at(0)

  const defaultEndDate = startOfHour(addHours(new Date(), 5));

  const initialValues: VehiculesListQueryParams = useMemo(() => ({
    structure_id: params?.structure_id || structureId || userPreferedStructure?.structure_id,
    available_between: params?.available_between?.start_date && params?.available_between?.end_date ? {
      start_date: toLocalDateTimeFormat(parseISO(params?.available_between.start_date)),
      end_date: toLocalDateTimeFormat(parseISO(params?.available_between.end_date))
    } : {
      start_date: toLocalDateTimeFormat(startOfHour(new Date())),
      end_date: toLocalDateTimeFormat(defaultEndDate)
    },
    usable: params?.usable !== undefined ? params?.usable : true
  }), [structureId, userPreferedStructure, defaultEndDate, params])

  const structureOptions = user?.meta.user_structures?.flatMap(s => ({ label: s.structure.name, value: `${s.structure_id}` })) ?? []

  const onSubmit = async (
    values: VehiculesListQueryParams,
    actions: FormikHelpers<VehiculesListQueryParams>
  ) => {
    // actions.resetForm()
    onSearch(values)
  }

  return (<>
    {userPreferedStructure && (<Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, ...props }) => (
        <Form>
          <ErrorMessage
            name="mission"
            component="div"
            className="field-error"
          />
          <ErrorMessage
            name="structure"
            component="div"
            className="field-error"
          />

          <VStack spacing="6" alignItems={'flex-start'}>
            <Field name='structure_id'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={form.errors.structure_id && form.touched.structure_id ? true : false}>
                  <FormLabel htmlFor='structure_id'>Structure</FormLabel>
                  <Select
                    isRequired
                    defaultValue={structureOptions?.find(e => e.value === userPreferedStructure?.structure_id.toString()) || structureOptions?.at(0)}
                    isInvalid={form.errors.structure_id ? true : false}
                    placeholder={`Sélectionner`}
                    name={field.name}
                    options={structureOptions}
                    value={structureOptions.find(e => e.value === field.value)}
                    onChange={(opt) => form.setFieldValue(field.name, opt?.value)}
                  />
                  <FormHelperText>L'UL ou la DT concernée</FormHelperText>
                  <FormErrorMessage>{form.errors.structure_id as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='available_between.start_date'>
              {({ field, form, ...other }: FieldProps) => (
                <FormControl isInvalid={form.errors['available_between.start_date'] && form.touched['available_between.start_date'] ? true : false}>
                  <FormLabel htmlFor='available_between.start_date'>Date de début</FormLabel>
                  <Input
                    {...field}
                    isRequired
                    min={format(startOfHour(new Date()), "yyyy-MM-dd'T'HH:mm")}
                    // max={form.values.available_between.end_date}

                    onChange={(opt) => {
                      form.setFieldValue(field.name, opt.target.value)
                      if (form.values.available_between.end_date && isBefore(
                        parseISO(form.values.available_between.end_date),
                        parseISO(opt.target.value),
                      )) {
                        form.setFieldValue('available_between.end_date', opt.target.value)
                      }
                    }}
                    aria-label="De"
                    type="datetime-local"
                  />
                  <FormHelperText>La date et l'heure de début de la réservation.</FormHelperText>
                  <FormErrorMessage>{form.errors['available_between.start_date'] as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='available_between.end_date'>
              {({ field, form, ...other }: FieldProps) => (
                <FormControl isInvalid={form.errors['available_between.end_date'] && form.touched['available_between.end_date'] ? true : false}>
                  <FormLabel htmlFor='available_between.end_date'>Date de fin</FormLabel>

                  <Input
                    {...field}
                    isRequired
                    aria-label="A"
                    min={form.values.available_between.start_date}
                    type="datetime-local"
                  />
                  <FormHelperText>La date approximative de retour.{' '}</FormHelperText>

                  <FormErrorMessage>{form.errors['available_between.end_date'] as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='usable'>
              {({ field, form, ...other }: FieldProps) => (
                <FormControl isInvalid={form.errors['usable'] && form.touched['usable'] ? true : false}>
                  <FormLabel htmlFor='usable'>
                    <HStack>
                      <Checkbox {...field} id='usable' isChecked={`${field.value}` === 'true'} />
                      <Text>N'afficher que les véhicules utilisables</Text>
                    </HStack>
                  </FormLabel>
                  <FormErrorMessage>{form.errors['usable'] as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>


            <HStack w={'100%'} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
              {onReset && <Button

                isLoading={props.isSubmitting}
                onClick={onReset}
                w={'100%'}
              >
                Réinitialiser
              </Button>}
              <Button
                variant="primary"
                isLoading={props.isSubmitting}
                isDisabled={!props.isValid}
                type='submit'
                leftIcon={<TbSearch />}
                w={'100%'}
              >
                Rechercher
              </Button>
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>) || <Loader />}
  </>)
}

export default ReservationSearchForm