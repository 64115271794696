import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  HStack,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateUserAttributes, UserAttributes } from '@/interfaces/user';

export interface UserEditFormProps {
  user: UserAttributes,
  onSubmit: (values: CreateUserAttributes, helpers: FormikHelpers<CreateUserAttributes>) => Promise<any>
}

const UserEditForm = ({ user, onSubmit }: UserEditFormProps) => {

  const initialValues = {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
  }

  const onSubmitClick = async (values: CreateUserAttributes, helpers: FormikHelpers<CreateUserAttributes>) => {
    await onSubmit(values, helpers)
    // helpers.resetForm()
  }

  return (<Formik<CreateUserAttributes>
    initialValues={initialValues}
    onSubmit={onSubmitClick}
  >
    {(props) => (
      <Form>

        <VStack spacing="3" alignItems={'flex-start'}>
          <Field name='email'>
            {({ field, form }: FieldProps) => (
              <FormControl isReadOnly isInvalid={form.errors.email && form.touched.email ? true : false}>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input {...field} isDisabled id='email' type='email' placeholder='Email' />
                <FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='first_name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.first_name && form.touched.first_name ? true : false}>
                <FormLabel htmlFor='first_name'>Prénom</FormLabel>
                <Input {...field} id='first_name' type='text' placeholder='Prénom' />
                <FormErrorMessage>{form.errors.first_name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='last_name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.last_name && form.touched.last_name ? true : false}>
                <FormLabel htmlFor='last_name'>Nom</FormLabel>
                <Input {...field} id='last_name' type='text' placeholder='Nom' />
                <FormErrorMessage>{form.errors.last_name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <HStack w={'100%'} flex={1} alignItems={'stretch'} justifyContent={'space-between'} pt={2}>
            <Button
              variant="primary"
              isLoading={props.isSubmitting}
              isDisabled={!props.dirty}
              w={'100%'}
              type='submit'
            >
              {props.submitCount > 0 ? "Compte mis à jour" : "Mettre à jour"}
            </Button>
          </HStack>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default UserEditForm