import { EquipmentAttributesMeta, EquipmentAttributesMetaMission } from "@/interfaces/equipment";
import { User } from "@/interfaces/user";
import { useMemo } from "react";


export function useEquipmentReservation(user?: User, meta?: EquipmentAttributesMeta) {

  const isUserInMission = (user: User | undefined, mission: EquipmentAttributesMetaMission) => {
    return user?.attributes.id && (mission.user_id === user?.attributes.id || mission.user_ids.includes(user?.attributes.id))
  }

  const isUserInRide = (user: User | undefined, ride: { user_id?: number }) => {
    return user?.attributes.id && (ride.user_id === user?.attributes.id)
  }

  const activeMissions = useMemo(() => meta?.active_missions || [], [meta?.active_missions])
  const nextMission = useMemo(() => meta?.next_mission || null, [meta?.next_mission])
  const activeRides = useMemo(() => meta?.active_rides || [], [meta?.active_rides])
  const hasActiveMission = useMemo(() => activeMissions.length > 0, [activeMissions])
  const hasActiveRide = useMemo(() => activeRides.length > 0, [activeRides])
  const hasActiveMissionForUser = useMemo(() => activeMissions && activeMissions.filter(m => isUserInMission(user, m)).length > 0, [activeMissions, user])
  const hasActiveRideForUser = useMemo(() => activeRides && activeRides.filter(m => isUserInRide(user, m)).length > 0, [activeRides, user])
  const currentActiveMissionForUser = useMemo(() => hasActiveMissionForUser && activeMissions.filter(m => isUserInMission(user, m))[0], [hasActiveMissionForUser, activeMissions, user])
  const currentActiveRideForUser = useMemo(() => hasActiveRideForUser && activeRides.filter(m => isUserInRide(user, m))[0], [hasActiveRideForUser, activeRides, user])
  const hasUpcomingMissionForOtherUser = useMemo(() => nextMission && !isUserInMission(user, nextMission), [nextMission, user])
  const upcomingMissionForOtherUser = useMemo(() => hasUpcomingMissionForOtherUser && nextMission, [hasUpcomingMissionForOtherUser, nextMission])
  const hasUpcomingMissionForUser = useMemo(() => nextMission && isUserInMission(user, nextMission), [nextMission, user])
  const upcomingMissionForUser = useMemo(() => hasUpcomingMissionForUser && nextMission, [hasUpcomingMissionForUser, nextMission])
  const hasActiveMissionForOtherUser = useMemo(() => activeMissions && activeMissions.filter(m => !isUserInMission(user, m)).length > 0, [activeMissions, user])
  const hasActiveRideForOtherUser = useMemo(() => activeRides && activeRides.filter(m => !isUserInRide(user, m)).length > 0, [activeRides, user])

  return {
    activeMissions,
    activeRides,
    nextMission,
    hasActiveMission,
    hasActiveRide,
    hasActiveMissionForUser,
    hasActiveRideForUser,
    currentActiveMissionForUser,
    currentActiveRideForUser,
    hasActiveMissionForOtherUser,
    hasActiveRideForOtherUser,
    hasUpcomingMissionForOtherUser,
    upcomingMissionForOtherUser,
    hasUpcomingMissionForUser,
    upcomingMissionForUser,
    isUserInMission,
  }
}
