// import "instantsearch.css/themes/algolia-min.css";

import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, HStack, Kbd, Link, Tag, Text, VStack, useBreakpointValue } from '@chakra-ui/react';

import {
  Highlight
} from "react-instantsearch";
import { BASE_APP_URL, equipmentSummaryRoute, goEquipmentRoute, missionRoute, vehiculeSummaryRoute } from "@/constants/routes";
import { STATES_LABELS } from '@/utils/states';
import { MdOutlineLocationOn } from 'react-icons/md';
import { truncate } from '@/utils/string';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { RecordId } from '@/interfaces/id';
import { ApiResource } from '@/utils/api';
import { useLazyImage } from '@/hooks/useLazyImage';
import { VehiculesListQueryParams } from '@/pages/Vehicules/VehiculesList';
import QueryString from 'qs';
import { VehiculeReservationBoxButton } from '../EquipmentActionButton/VehiculeReservationBox/Button';
import { TbArrowsLeftRight, TbArrowWaveLeftDown, TbEye, TbFlag2Filled, TbFlag3, TbMapPin } from 'react-icons/tb';
import { useMemo } from 'react';


export interface VehiculeCardProps {
  orgId: RecordId
  queryParams?: VehiculesListQueryParams
  data: ApiResource<EquipmentAttributes, EquipmentAttributesMeta>
}

export const VehiculeCard = ({ orgId, data, queryParams }: VehiculeCardProps) => {

  const blankUrl = "/blank.png"
  const { attributes: vehicule, meta } = data
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const navigate = useNavigate()
  const { imageUrl, isImageLoaded } = useLazyImage(vehicule.image_url, vehicule.image_thumb_url)
  // const [searchParams, setSearchParams] = useSearchParams();
  const url = useMemo(() => BASE_APP_URL + goEquipmentRoute(orgId, data?.attributes.slug), [orgId, data?.attributes])

  const onReservation = (equipmentId: RecordId, missionId: RecordId) => {
    navigate(missionRoute(orgId, missionId))
  }

  return (
    <Box bg={'bg.subtle'}
      borderRadius={3}
      p={0}
      flex={1}
      alignContent={'flex-start'}
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      key={vehicule.id}
    >
      <HStack alignItems={'stretch'} spacing={0}>
        <Box bg={'bg.subtle'}
          borderRadius={3}
          bgImage={`url(${imageUrl || blankUrl})`}
          backgroundPosition="center center"
          backgroundSize="cover"
          p={0}
          flex={'1 0 150px'}
          maxW={'120px'}
          minW={{ base: '30%', md: '200px' }}
          alignContent={'flex-start'}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
        >
          {/* <Tag lineHeight={0} m={1} textTransform={'uppercase'} size='sm' fontSize={'xs'} colorScheme={STATES_LABELS[vehicule.current_availability].color}>{STATES_LABELS[vehicule.current_availability].label}</Tag> */}
        </Box>
        <VStack spacing={1} flex={1} alignItems={'stretch'} p={0}>
          <HStack px={3} pt={3} alignContent={'flex-start'} justifyContent={'space-between'}>
            <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}><Kbd noOfLines={1} color='fg.subtle'>{truncate(vehicule.serial, 20)}</Kbd></Text>
            <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{meta?.equipment_type?.name}</Text>
          </HStack>
          <HStack
            flexWrap={'wrap'}
            align={'flex-start'}
            spacing={{ base: 1, md: 3 }}
            flexDirection={{ base: 'column', md: 'row'}}
            alignContent={'flex-start'}
            justifyContent={'space-between'}
            px={3}
          >
            <HStack
              spacing={3}
              alignContent={'flex-start'}
              as={RouterLink}
              to={vehiculeSummaryRoute(orgId, vehicule.slug) + '?' + QueryString.stringify(queryParams)}
            >
              <Text noOfLines={2} fontSize={'lg'}>{vehicule.name}</Text>
              <Text noOfLines={1} color={'fg.subtle'} fontSize={'md'}>{vehicule.manufacturer} {vehicule.model}</Text>
            </HStack>
          </HStack>
          <HStack spacing={3} alignItems={{ base: 'flex-start', md: 'center' }} flexDirection={{ base: 'column', md: 'row' }} alignContent={'flex-start'} px={3} pb={3}>
            <HStack spacing={1}>
              <Tag lineHeight={0} textTransform={'uppercase'} size='sm' fontSize={'xs'} variant='outline' colorScheme={STATES_LABELS[vehicule.current_state].color}>{STATES_LABELS[vehicule.current_state].label}</Tag>

              {meta?.current_location?.id !== meta?.structure?.id && <HStack spacing={1}>
                <Tag lineHeight={0} textTransform={'uppercase'} size='sm' fontSize={'xs'} variant='outline' colorScheme={'purple'}>externe</Tag>
              </HStack>}
            </HStack>

            <HStack spacing={1}>
              <Text color='fg.subtle'><MdOutlineLocationOn /></Text>
              <Text noOfLines={1} color='fg.subtle'>{meta?.current_location?.short_name}</Text>
              {meta?.current_location?.id !== meta?.structure?.id && <>
                <Text color='fg.subtle'><TbArrowsLeftRight /></Text>
                <Text noOfLines={1} color='fg.subtle'> {meta?.structure?.short_name}</Text>
              </>}
            </HStack>

          </HStack>

          {(!isDesktop || queryParams?.available_between) && <HStack p={3} borderTop={'1px solid'} borderTopColor={'gray.200'} spacing={3} alignContent={'flex-start'}>
            {!queryParams?.available_between && <Button
              as={RouterLink}
              size={'sm'}
              variant={'link'}
              textDecoration={'underline'}
              color={'fg.subtle'}
              to={url}
            >
              Utiliser ce véhicule
            </Button>}

            {queryParams?.available_between && (<VehiculeReservationBoxButton
              equipmentId={vehicule?.id || ''}
              buttonLabel="Réserver"
              queryParams={queryParams}
              equipment={vehicule}
              variant={'primary'}
              textTransform={'uppercase'}
              size={'sm'}
              fontWeight={'bold'}
              // colorScheme='brand'
              onReservationSuccess={onReservation}
            />)}

          </HStack>}

        </VStack>
      </HStack>
    </Box>
  );

}
