import { useField, useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react"
import { Box, useDisclosure } from "@chakra-ui/react"
import { Option, OptionList } from "@/components/AsyncSelect/AsyncSelect"
import { useNanuqQuery } from "@/hooks/query"
import { ApiResourceResponse } from "@/utils/api";
import { RecordId } from "@/interfaces/id";
import { getOrganizationEquipments, getOrganizationEquipmentsKey } from "@/api/equipment";
import { useDebounce } from "use-debounce";
import { AsyncSelect, SingleValue } from "chakra-react-select";
import axios from "axios";
import { EquipmentAttributes } from "@/interfaces/equipment";



export interface EquipmentFieldProps {
  name: string,
  orgId: RecordId,
  usedIds?: RecordId[],
  onEquipmentSelect?: (equipmentId: RecordId) => void
}

export const EquipmentField = (props: EquipmentFieldProps) => {
  const { name, onEquipmentSelect, orgId, usedIds } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchValue, setSearchValue] = useState("");
  const [search] = useDebounce(searchValue, 500);

  // const queryKey = [getOrganizationEquipmentsKey, orgId, search]

  // const {
  //   isLoading,
  //   data,
  //   error
  // } = useNanuqQuery(queryKey, () => getOrganizationEquipments(orgId, 1, { search }))


  const [field, meta] = useField(props);

  const form = useFormikContext<{ equipment_id: RecordId }>();

  const {
    values,
    getFieldProps,
    setValues,
    setFieldValue,
    errors,
    setFieldError,
    touched
  } = form

  const [currentValue, setCurrentValue] = useState<Option>()
  const [options, setOptions] = useState<OptionList>([])

  // const options: OptionList = useMemo(() => {
  //   return data?.data?.map(o => ({ label: o.attributes.name, value: o.id.toString() })) || []
  // }, [data])

  useEffect(() => {
    if (!currentValue || (currentValue.value !== `${field.value}`)) {
      const defaultValue = options ? options.find(option => option.value === field.value?.toString()) : { label: `${field.value} ?`, value: field.value }
      setCurrentValue(defaultValue)
    }
  }, [options, field.value, setCurrentValue, currentValue])


  const onEquipmentSave = async (newEquipment: ApiResourceResponse<EquipmentAttributes, unknown, unknown>) => {
    console.log("New equipment saved: ", newEquipment)

    await setFieldValue(name, `${newEquipment.data.id}`)
    if (onEquipmentSelect) {
      onEquipmentSelect(newEquipment.data.id)
    }
  }

  const promiseOptions = async (inputValue: string): Promise<OptionList> => {
    setSearchValue(inputValue)
    const data = await getOrganizationEquipments(orgId, 1, { search: inputValue })
    const elements = data?.data?.map(o => ({ label: o.attributes.name, value: o.id.toString() })) || []
    setOptions(elements)
    return elements.filter(e => !(usedIds || []).includes(e.value))
  }

  const onChange = (option: SingleValue<Option>) => {
    console.log("Setting field", name, option?.value)
    setFieldValue(name, option?.value)
  }


  return (
    <Box w={'100%'}>
    <AsyncSelect
      isInvalid={errors[name] && touched[name] ? true : false}
      placeholder={`Sélectionner`}
      loadOptions={promiseOptions}
      isSearchable
      cacheOptions
      defaultOptions
      // isLoading={isLoading}
      value={currentValue}
      onChange={onChange}
    />
    </Box>
  );
};
