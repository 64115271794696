import { EquipmentEvent } from "@/interfaces/equipment";
import { IconType } from "react-icons";
import { TbAntenna } from "react-icons/tb";
import { EVENT_LABELS, EquipmentEventMetaLabel, STATES_LABELS, StateKey } from "./states";
import { sortBy } from "./object";
import { VehiculesListQueryParams } from "@/pages/Vehicules/VehiculesList";
import { eachDayOfInterval, endOfDay, format, formatISO, isSameDay, parseISO, startOfDay } from "date-fns";
import QueryString from "qs";
import { ApiResource } from "./api";
import { MissionEquipmentPlanning, MissionEquipmentPlanningMeta } from "@/interfaces/missionEquipment";
import { CalendarEvent as ScheduleXCalendarEvent } from "@schedule-x/calendar";
import { CalendarEvent } from "@/components/EquipmentTimeline/EquipmentTimeline";

export const EQUIPMENT_LABELS: {[key: string]: { icon: IconType }} = {
  'Radio': {
    icon: TbAntenna
  }
}

export const EQUIPMENT_CATEGORIES: {[key: string]: string} = {
  'radio': 'Radio',
  'vehicule': 'Véhicule',
  'lot': 'Lot',
  'salle': 'Salle',
  'autre': 'Autre',
}
export const EQUIPMENT_CATEGORIES_OPTIONS = Object.keys(EQUIPMENT_CATEGORIES).map(o => ({ label: EQUIPMENT_CATEGORIES[o], value: o }))

export const LABEL_FOR_ACTION_FIELDS: { [key in EquipmentEventMetaLabel]: string } = {
  notes: "Notes supplémentaires",
  destination: "Destination"
}

export const sortEvents = (allowed_transitions: EquipmentEvent[], current: StateKey): EquipmentEvent[] => {

  const sortedActions = allowed_transitions.map(tr => {
    let distance = EVENT_LABELS[tr].order - STATES_LABELS[current].order
    if (distance < 0) {
      distance = Math.abs(distance) + 5
    }

    return { name: tr, distance }
  })

  return sortedActions.sort(sortBy('distance')).map(e => e.name)
}

export const parseEquipmentQueryParams = (searchParams: URLSearchParams): VehiculesListQueryParams => {

  const parsed = QueryString.parse(searchParams.toString()) as VehiculesListQueryParams

  if (parsed['usable'] === undefined) {
    parsed.usable = true
  } else {
    parsed.usable = (`${parsed.usable}` === 'true' ? true : false)
  }


  if (parsed.available_between?.start_date) {
    parsed.available_between.start_date = formatISO(parseISO(parsed.available_between.start_date))
  }
  if (parsed.available_between?.end_date) {
    parsed.available_between.end_date = formatISO(parseISO(parsed.available_between.end_date))
  }
  return parsed
}

function splitMutiDayEvent(event: ScheduleXCalendarEvent): ScheduleXCalendarEvent[] {
  const start = parseISO(event.start);
  const end = parseISO(event.end);

  const days = eachDayOfInterval({ start, end });

  return days.map((day, index) => {
    if (index === 0 && index === days.length - 1) {
      // Cas où le début et la fin sont le même jour
      return event
    } else if (index === 0) {
      // Premier jour
      return { ...event, end: format(endOfDay(day), 'yyyy-MM-dd HH:mm') };
    } else if (index === days.length - 1) {
      // Dernier jour
      return { ...event, start: format(startOfDay(day), 'yyyy-MM-dd HH:mm') };
    } else {
      // Jours intermédiaires
      return { ...event, start: format(startOfDay(day), 'yyyy-MM-dd HH:mm'), end: format(endOfDay(day), 'yyyy-MM-dd HH:mm') };
    }
  });
}


export const formatMissionEquipmentsforMissionCalendar = (data: ApiResource<MissionEquipmentPlanning, MissionEquipmentPlanningMeta>[]): ScheduleXCalendarEvent[] => {

  const filteredData: ScheduleXCalendarEvent[] = data.map(r => ({
    title: r.attributes.equipment_name,
    id: r.attributes.id,
    mission_id: r.attributes.mission_id,
    mission_ids: [r.attributes.mission_id],
    equipment_id: r.attributes.equipment_id,
    mission_type: r.attributes.mission_type,
    mission_name: r.attributes.mission_name,
    start: format(parseISO(r.attributes.begin_at), 'yyyy-MM-dd HH:mm'),
    end: format(parseISO(r.attributes.end_at), 'yyyy-MM-dd HH:mm'),
  })) || []

  // return filteredData

  const reducedData = filteredData.reduce<{ [key: string]: ScheduleXCalendarEvent }>((prev: { [key: string]: any }, curr: any) => {
    const key = `${curr.start}-${curr.end}-${curr.equipment_id}`
    if (prev[key]) {
      prev[key].mission_ids = prev[key].mission_ids || []
      prev[key].mission_ids = [...new Set([...prev[key].mission_ids, curr.mission_id])]
    } else {
      prev[key] = curr
    }
    return prev
  }, {})

  return Object.values(reducedData) //.flatMap(e => splitMutiDayEvent(e))
}

export const formatMissionEquipmentsforMissionTimeline = (data: ApiResource<MissionEquipmentPlanning, MissionEquipmentPlanningMeta>[]): CalendarEvent[] => {

  const filteredData: CalendarEvent[] = data.map(r => ({
    group: r.attributes.equipment_name,
    title: r.attributes.mission_name,
    id: r.attributes.id,
    mission_id: r.attributes.mission_id,
    mission_ids: [r.attributes.mission_id],
    equipment_id: r.attributes.equipment_id,
    mission_type: r.attributes.mission_type,
    mission_name: r.attributes.mission_name,
    start: parseISO(r.attributes.begin_at),
    end: parseISO(r.attributes.end_at),
  })) || []

  // return filteredData

  const reducedData = filteredData.reduce<{ [key: string]: CalendarEvent }>((prev: { [key: string]: any }, curr: any) => {
    const key = `${curr.start}-${curr.end}-${curr.equipment_id}`
    if (prev[key]) {
      prev[key].mission_ids = prev[key].mission_ids || []
      prev[key].mission_ids = [...new Set([...prev[key].mission_ids, curr.mission_id])]
    } else {
      prev[key] = curr
    }
    return prev
  }, {})

  return Object.values(reducedData) //.flatMap(e => splitMutiDayEvent(e))
}



export const formatMissionEquipmentsforEquipmentCalendar = (data: ApiResource<MissionEquipmentPlanning, MissionEquipmentPlanningMeta>[]): ScheduleXCalendarEvent[] => {

  const filteredData: ScheduleXCalendarEvent[] = data.map(r => ({
    title: r.attributes.mission_name,
    id: r.attributes.id,
    mission_id: r.attributes.mission_id,
    mission_ids: [r.attributes.mission_id],
    equipment_id: r.attributes.equipment_id,
    equipment_ids: [r.attributes.equipment_id],
    mission_type: r.attributes.mission_type,
    mission_name: r.attributes.mission_name,
    start: format(parseISO(r.attributes.begin_at), 'yyyy-MM-dd HH:mm'),
    end: format(parseISO(r.attributes.end_at), 'yyyy-MM-dd HH:mm'),
  })) || []

  // return filteredData

  const reducedData = filteredData.reduce<{ [key: string]: ScheduleXCalendarEvent }>((prev: { [key: string]: any }, curr: any) => {
    const key = `${curr.start}-${curr.end}-${curr.mission_id}`
    if (prev[key]) {
      prev[key].equipment_ids = prev[key].equipment_ids || []
      prev[key].equipment_ids = [...new Set([...prev[key].equipment_ids, curr.equipment_id])]
    } else {
      prev[key] = curr
    }
    return prev
  }, {})



  return Object.values(reducedData)
}

