
import { useEquipmentReservation } from '@/hooks/useEquipmentReservation';
import { EquipmentAttributesMeta } from '@/interfaces/equipment';
import { User } from '@/interfaces/user';
import { Alert, AlertDescription, AlertTitle, Badge, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { TbCalendar, TbCalendarCancel, TbTruck } from 'react-icons/tb';
import { formatDateShort, formatDistanceShort } from '@/utils/datesFormat';
import { parseISO } from 'date-fns';
import { goEquipmentSetupMissionRoute } from '@/constants/routes';
import { RecordId } from '@/interfaces/id';
import { Link } from 'react-router-dom';
import ButtonWithAlert from '@/components/ButtonWithAlert/ButtonWithAlert';
import { useMemo } from 'react';
import { useOrganizations } from '@/contexts/useOrganizationsContext';



export interface BoxForMissionStatusProps {
  equipmentId: RecordId,
  orgId: RecordId,
  meta?: EquipmentAttributesMeta | undefined,
  user?: User | undefined,
  onCancel: (missionEquipmentId: RecordId) => void
  onClose: (equipmentId: RecordId, missionId: RecordId) => void
}


export const BoxForMissionStatus = ({ equipmentId, orgId, meta, user, onClose, onCancel }: BoxForMissionStatusProps) => {

  const { ability } = useOrganizations()

  const {
    activeMissions,
    nextMission,
    hasActiveMission,
    hasActiveRide,
    hasActiveMissionForUser,
    hasActiveRideForUser,
    currentActiveMissionForUser,
    currentActiveRideForUser,
    hasActiveMissionForOtherUser,
    hasActiveRideForOtherUser,
    upcomingMissionForOtherUser,
    upcomingMissionForUser,
    isUserInMission,
  } = useEquipmentReservation(user, meta)
  
  const canMakeReservation = useMemo(() => ability?.can('update', 'equipment'), [ability])

  return (<>
    <HStack p={3}>
      <Text fontSize={'large'} justifyContent={'center'} textAlign={'center'} fontWeight={'bold'}>
        <HStack>
          <Text color={'red.600'}><TbCalendar /></Text>
          <Text>Réservation en cours</Text>
        </HStack>
      </Text>
    </HStack>
    <Divider />
    {activeMissions.length == 0 && (<>
      <Text p={3} color={'fg.subtle'}>Aucune réservation en cours</Text>
    </>)}

    <VStack p={3} flex={1} spacing={3} alignItems={'stretch'} justifyContent={'space-between'} divider={<Divider />}>
    {activeMissions.map(mission => (<>
      <Alert status={isUserInMission(user, mission) ? 'success' : 'warning'} borderRadius={4}>
        <VStack flex={1} spacing={3} alignItems={'stretch'} justifyContent={'space-between'}>
          <AlertTitle><Text>{mission.name}</Text><Text fontSize={'sm'}>{formatDateShort(parseISO(mission.begin_at || ''))} - {formatDateShort(parseISO(mission.end_at || ''))}</Text></AlertTitle>
          <AlertDescription>
            <Text>Réservé par {mission.user_full_name} {mission.user_id === user?.attributes.id && <Badge size={'sm'}>C'est vous</Badge>}</Text>

          </AlertDescription>
          {!hasActiveRide && (
            <VStack spacing={6} alignItems={'stretch'} justifyContent={'space-between'}>
              <Button
                as={Link}
                to={goEquipmentSetupMissionRoute(orgId, equipmentId, mission.id)}
                // flex={1}
                colorScheme={currentActiveMissionForUser ? 'green' : 'orange'}
                data-button="start-ride"
                bgColor={currentActiveMissionForUser ? 'green.200' : 'orange.200'}
                color={currentActiveMissionForUser ? 'green.800' : 'orange.800'}
                leftIcon={<TbTruck />}
              >Partir maintenant
              </Button>
              {currentActiveMissionForUser && canMakeReservation && <ButtonWithAlert
                onAccept={() => onClose(equipmentId, mission.id)}
                buttonText='Cloturer la mission'
                variant={'link'}
                dialogText="La mission sera cloturée, et les véhicules réservés seront de nouveau disponibles"
                color={'green.800'}
                data-button="cancel-ride"
                leftIcon={<TbCalendarCancel />}
              />}
            </VStack>
          )}
        </VStack>
      </Alert>
    </>))}
    </VStack>

  </>);
}
