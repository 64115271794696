
import { EquipmentAttributes, TakeoutState } from '@/interfaces/equipment';
import { Box, Button, Divider, FormLabel, HStack, Radio, RadioGroup, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { useState } from 'react';
import { PiCarDuotone, PiCarFill, PiCarProfileDuotone, PiCarProfileFill, PiCarSimpleDuotone, PiCarSimpleFill } from "react-icons/pi";
import { TbCheck, TbSquareRoundedCheckFilled } from 'react-icons/tb';
import ButtonWithAlert from '@/components/ButtonWithAlert/ButtonWithAlert';


export interface StepTwoVehiculePicturesProps {
  equipment: EquipmentAttributes,
  currentState: TakeoutState,
  onNext: (state: TakeoutState) => void
}

export interface VehiculePictureButtonProps {
  image?: any,
  label: string,
  icon: any,
  onChange: (event: any) => void
}

export const VehiculePictureButton = ({
  image,
  label,
  icon,
  onChange,
}: VehiculePictureButtonProps) => {

  return (
    <label style={{ display: 'block' }}>
      <HStack justifyContent={'space-between'}>
        <HStack>
          <HStack position={'relative'}>
            <Text color={image ? 'currentcolor' : 'red.600'} mr={2} fontSize={'xx-large'}>{icon}</Text>
            {image && <Text position='absolute' right={0} bottom={0} color='green.600'><TbSquareRoundedCheckFilled /></Text>}
          </HStack>
          <Text fontSize={'md'} color={image ? 'green.600' : 'currentcolor'}>{label}</Text>
        </HStack>
        <Button as={Box} size={'sm'}>{image ? 'Modifier' : 'Ajouter'}</Button>
      </HStack>
      <input style={{ display: 'none' }} type="file" capture="environment" accept="image/*" onChange={onChange} />
    </label>)
}

export const StepTwoVehiculePictures = ({
  equipment,
  currentState,
  onNext,
}: StepTwoVehiculePicturesProps) => {

  const { equipmentId, missionId } = useParams()
  const { data: user } = useUser();
  const { ability, selectedOrganizationId } = useOrganizations()
  const [imageFront, setImageFront] = useState(currentState.imageFront);
  const [imageBack, setImageBack] = useState(currentState.imageBack);
  const [imageLeft, setImageLeft] = useState(currentState.imageLeft);
  const [imageRight, setImageRight] = useState(currentState.imageRight);

  const handleImageFrontChange = event => setImageFront(event.target.files[0]);
  const handleImageBackChange = event => setImageBack(event.target.files[0]);
  const handleImageLeftChange = event => setImageLeft(event.target.files[0]);
  const handleImageRightChange = event => setImageRight(event.target.files[0]);

  const onSubmit = () => {
    console.log("validate", imageFront, imageBack, imageLeft, imageRight)
    currentState = {
      ...currentState,
      imageFront,
      imageBack,
      imageLeft,
      imageRight,
    }
    onNext(currentState)
  }

  return (
    <VStack spacing={6} flex={1} alignItems={'stretch'}>
      <Box
        px={3}
        textAlign={'left'}
      >
        <VStack spacing={6} flex={1} alignItems={'stretch'}>
          <Text>Veuillez prendre <b>4 photos</b> du véhicule</Text>
          <Divider />
          <HStack flex={1} alignItems={'stretch'} justifyContent={'flex-start'}>
            <form style={{ maxWidth: '100%', width: '100%'}} onSubmit={onSubmit}>
              <VStack spacing={6} alignItems={'stretch'} w={'100%'}>
                <VehiculePictureButton
                  image={imageFront}
                  label="Photo de l'avant"
                  icon={<PiCarDuotone />}
                  onChange={handleImageFrontChange}
                />
                <Divider />
                <VehiculePictureButton
                  image={imageLeft}
                  label="Photo du coté gauche"
                  icon={<PiCarProfileDuotone />}
                  onChange={handleImageLeftChange}
                />
                <Divider />
                <VehiculePictureButton
                  image={imageRight}
                  label="Photo du coté droit"
                  icon={<Box transform={'scale(-1, 1)'}><PiCarProfileDuotone /></Box>}
                  onChange={handleImageRightChange}
                />
                <Divider />
                <VehiculePictureButton
                  image={imageBack}
                  label="Photo de l'arrière"
                  icon={<PiCarSimpleDuotone />}
                  onChange={handleImageBackChange}
                />
              </VStack>
            </form>
          </HStack>
        </VStack>
      </Box>
      <VStack flex={1} alignItems={'stretch'}>
        <Button colorScheme='brand' isDisabled={!imageFront && !imageBack && !imageLeft && !imageRight} onClick={onSubmit}>Valider</Button>
        <ButtonWithAlert
          buttonText={'Continuer sans photos'}
          dialogText="Prendre des photos de votre véhicule vous protège en cas de litige ou de déterioration."
          dialogTitle="Êtes vous sur ?"
          acceptText="Continuer quand même"
          variant={'ghost'}
          colorScheme='grey'
          onAccept={onSubmit} />
        </VStack>
    </VStack>
  );
}
