import {
  Button,
  Input,
  FormControl,
  FormErrorMessage,
  VStack,
  HStack,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateEquipmentHistoryAttributes, EquipmentHistoryAttributes } from '@/interfaces/equipmentHistory';

import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { addHistoryToEquipment } from '@/api/equipmentHistory';
import { useUser } from '@/hooks/auth';
import { RiSendPlaneFill } from 'react-icons/ri';

export interface EquipmentHistoryFormProps {
  equipmentHistory?: EquipmentHistoryAttributes,
  equipmentId: RecordId,
  onSave: (newEquipmentHistory: ApiResourceResponse<EquipmentHistoryAttributes>) => Promise<any>
}

const NewEquipmentHistoryForm = ({ equipmentHistory, equipmentId, onSave }: EquipmentHistoryFormProps) => {

  const { data: user } = useUser()

  const initialValues: CreateEquipmentHistoryAttributes = {
    title: equipmentHistory?.title || '',
    description: equipmentHistory?.description,
    equipment_id: equipmentId,
    user_id: user?.attributes?.id,
  }

  const { data, mutateAsync, reset } = useMutation({
    mutationFn: (equipmentHistoryPayload: CreateEquipmentHistoryAttributes) => addHistoryToEquipment(equipmentId || '', equipmentHistoryPayload)
  })

  const onSubmit = async (
    values: CreateEquipmentHistoryAttributes,
    actions: FormikHelpers<CreateEquipmentHistoryAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newData = await mutateAsync(values);
        actions.resetForm()
        onSave(newData)
        reset()
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }
  
  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, ...props }) => (
      <Form>
        <ErrorMessage
          name="equipmentHistory"
          component="div"
          className="field-error"
        />
        <HStack spacing="3" alignItems={'flex-start'}>
          <VStack width={'100%'} spacing="3" flex={1} alignItems={'flex-start'}>
            <Field name='title'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={form.errors.title && form.touched.title ? true : false}>
                  <Input size='md' bg={'bg.muted'} variant='filled' {...field} id='title' type='text' placeholder='Ajouter une note...' />
                  <FormErrorMessage>{form.errors.title as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>

          <Button
            variant="primary"
            isLoading={props.isSubmitting}
            disabled={!props.dirty}
            type='submit'
            size='md'
          >
            <RiSendPlaneFill />
          </Button>
        </HStack>
      </Form>
    )}
  </Formik>
  )
}

export default NewEquipmentHistoryForm