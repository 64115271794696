// import "instantsearch.css/themes/algolia-min.css";

import { Button, ButtonProps, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';

import { TbInfoCircle } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { VehiculeDetails } from '@/screens/VehiculeDetails/VehiculeDetails';
import { ApiResource } from '@/utils/api';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';


export interface EquipmentDetailsModalProps extends ButtonProps {
  data: ApiResource<EquipmentAttributes, EquipmentAttributesMeta>
}

export const EquipmentDetailsModal = ({
  data,
  ...props
}: EquipmentDetailsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { orgId } = useParams()

  return (
    <>
      <Button size={'sm'} leftIcon={<TbInfoCircle />} onClick={onOpen} {...props}>Plus d'infos</Button>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Détails de {data.attributes.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VehiculeDetails data={data} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
