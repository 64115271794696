
import { getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { Box, Stack, Text } from '@chakra-ui/react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { LoaderFunction, useParams } from 'react-router-dom';
import { deleteEquipmentHistory, getEquipmentHistories, getEquipmentHistoriesKey } from '@/api/equipmentHistory';
import { EquipmentHistoryTable } from '@/screens/EquipmentHistoryTable/EquipmentHistoryTable';
import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import NewEquipmentHistoryForm from '@/screens/EquipmentHistoryForm/NewEquipmentHistoryForm';
import { EquipmentHistoryAttributes } from '@/interfaces/equipmentHistory';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export interface VehiculeHistoryProps {
  readonly?: boolean
}

export const VehiculeHistory = ({ readonly = false }: VehiculeHistoryProps) => {

  const { orgId, equipmentId } = useParams()
  const queryClient = useQueryClient()

  const [{ pageIndex, pageSize }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const historyQueryKey = [getEquipmentHistoriesKey, equipmentId, pageIndex]

  const { data: history, refetch: refetchHistory } = useNanuqQuery(
    historyQueryKey,
    () => equipmentId ? getEquipmentHistories(
      equipmentId,
      pageIndex + 1,
    ) : null
  )
  const deleteEquipmentHistoryMutation = useOptimisticDeleteMutation<ApiResourceResponse<EquipmentHistoryAttributes>, RecordId>(queryClient, deleteEquipmentHistory, historyQueryKey)


  const onComment = async (newEquipmentHistory: ApiResourceResponse<EquipmentHistoryAttributes>) => {
    refetchHistory()
  }

  const onEdit = async (equipmentId: RecordId) => {

  }

  const onDelete = async (equipmentHistoryId: RecordId) => {
    deleteEquipmentHistoryMutation.mutate(equipmentHistoryId, {
      onSettled: () => refetchHistory()
    })
  }

  if (!history) {
    return <></>
  }

  return (
    <Stack width={'100%'} px={{ base: 1, md: 1 }} pb={3} spacing={3}>
      <EquipmentHistoryTable data={history} onDelete={onDelete} onEdit={onEdit} onPaginationChange={setPagination} />
      {!readonly && <NewEquipmentHistoryForm onSave={onComment} equipmentId={equipmentId!} />}
    </Stack>
  );
}

export default VehiculeHistory;