import { EquipmentAttributes, EquipmentEvent } from "@/interfaces/equipment"
import { sortEvents } from "@/utils/equipment"
import { EVENT_LABELS } from "@/utils/states"
import { Button, ButtonGroup, ButtonGroupProps, ButtonProps, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure, useToast } from "@chakra-ui/react"
import { useState } from "react"
import { TbChevronDown } from "react-icons/tb"
import { EquipmentActionMetadata, EquipmentActionModal } from "./Modal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getEquipmentHistoriesKey } from "@/api/equipmentHistory"
import { actionOnEquipment, getEquipmentQuery } from "@/api/equipment"


export interface EquipmentActionProps extends ButtonGroupProps {
  equipment: EquipmentAttributes,
  allowed_transitions: EquipmentEvent[],
  buttonProps?: Partial<ButtonProps>,
  isLoading?: boolean,
}

export const EquipmentActionButtonForEquipment = ({
  equipment,
  allowed_transitions,
  isLoading = false,
  buttonProps = {},
  ...props
}: EquipmentActionProps) => {

  const queryClient = useQueryClient()
  const toast = useToast()

  const [selectedAction, setSelectedAction] = useState <EquipmentEvent>()

  const otherActions = sortEvents(allowed_transitions, equipment.current_state)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onActionSelected = (action: EquipmentEvent) => {
    setSelectedAction(action)
    onOpen()
  }

  const { data, mutate: mutateAction, isPending: actionPending } = useMutation({
    mutationFn: (payload: { action: EquipmentEvent, meta: EquipmentActionMetadata }) => actionOnEquipment(equipment.id, payload.action, payload.meta)
  })

  const onAction = async (action: EquipmentEvent, meta: EquipmentActionMetadata) => {
    console.log({ action, meta, equipment })
    await mutateAction({ action, meta }, {
      onSettled: async () => {
        const toInvalidate = [
          getEquipmentQuery(equipment.id).queryKey,
          getEquipmentQuery(equipment.slug).queryKey,
          [getEquipmentHistoriesKey, equipment.id],
          [getEquipmentHistoriesKey, equipment.slug],
        ]

        console.log("Invalidate !", toInvalidate)
        for await (const key of toInvalidate) {
          await queryClient.invalidateQueries({ queryKey: key })
        }
        return true
      },
      onSuccess: async () => {
        onClose()
        setSelectedAction(undefined)
        toast({ title: 'Action effectuée', status: 'success', isClosable: true, duration: 2000 })
      },
      onError: (err, newTodo, context) => {
        console.log("onError", { err, newTodo, context })
        toast({ title: "Erreur lors de l'action'", description: err.message, status: 'error', isClosable: true, duration: 2000 })
      }
    })
  }

  return (
    <ButtonGroup isAttached isDisabled={actionPending} variant='outline' {...props}>
      {selectedAction && <EquipmentActionModal action={selectedAction} onAction={onAction} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
      <Menu>
        <MenuButton
          // colorScheme={'grey'}
          isDisabled={props.isDisabled || isLoading || actionPending}
          as={Button}
          variant={'secondary'}
          aria-label="Plus d'actions"
          rightIcon={<TbChevronDown />}
          {...buttonProps}
        >
          Actions
        </MenuButton>
        <MenuList>
          {otherActions.map(ac => {
            const { label, icon: OtherIcon, color } = EVENT_LABELS[ac]

            return (<MenuItem onClick={() => onActionSelected(ac)} key={ac} icon={<OtherIcon />}>{label}</MenuItem>)
          })}
        </MenuList>
      </Menu>
    </ButtonGroup>)
}