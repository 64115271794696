import { Badge, Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react'
import * as React from 'react'
import { IconType } from 'react-icons'

interface TabNavButtonProps extends ButtonProps {
  icon?: IconType
  label: string
  counter?: string | number | null
  to?: string
}

export const TabNavButton = (props: TabNavButtonProps) => {
  const { icon, label, counter, to, ...buttonProps } = props
  // const isDesktop = useBreakpointValue({ base: false, md: true, lg: true })
  const isDesktop = true

  return (
    <Button variant="tabnav" to={to} justifyContent="start" {...buttonProps}>
      <HStack spacing="3">
        <HStack spacing="3">
          {icon  && <Icon as={icon} boxSize="6" color="subtle" />}
          {isDesktop && <Text>{label}</Text>}
        </HStack>
        {counter && <Badge color="currentcolor" alignSelf={"flex-end"}>{counter}</Badge>}
      </HStack>
    </Button>
  )
}