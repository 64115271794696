// import "instantsearch.css/themes/algolia-min.css";

import { useParams, useSearchParams } from 'react-router-dom';
import { Box, HStack, Input, InputGroup, InputLeftElement, Stack, Text, VStack } from '@chakra-ui/react';

import { useNanuqQuery } from '@/hooks/query';
import { getOrganizationVehicules, getOrganizationVehiculesKey } from '@/api/equipment';
import { VehiculeCard } from '@/screens/VehiculeCard/VehiculeCard';
import { ChangeEventHandler, memo, useMemo, useState } from 'react';
import { VehiculesListToolbar } from './VehiculesListToolbar';
import { RecordId } from '@/interfaces/id';
import { parseEquipmentQueryParams } from '@/utils/equipment';
import { StructureSelectHeader } from '@/components/StructureSelectHeader/StructureSelectHeader';
import QueryString from 'qs';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import MutedLegend from '@/components/MutedLegend/MutedLegend';
import { MdOutlineLocationOn } from 'react-icons/md';
import { TbSearch } from 'react-icons/tb';
import { useDebounce } from 'react-use';

export interface VehiculesListQueryParams {
  structure_id?: RecordId
  equipment_type_ids?: string[]
  categories?: string[]
  seats_range?: [number, number]
  current_availability?: string
  current_state?: string
  search?: string
  usable?: boolean
  available_between?: {
    start_date?: string
    end_date?: string
  }
}

export interface VehiculesListProps {

}

export const VehiculesList = memo(function VehiculesListRaw () {

  const { orgId } = useParams()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => parseEquipmentQueryParams(searchParams), [searchParams])

  const { isLoading, data } = useNanuqQuery(
    [getOrganizationVehiculesKey, orgId, queryParams],
    () => orgId ? getOrganizationVehicules(orgId, 1, queryParams) : null
  )

  const onStructureChange = (structure_id: RecordId | undefined) => {
    setSearchParams(QueryString.stringify({ ...queryParams, structure_id }))
  }

  const [searchText, setSearchText] = useState('');

  const [, cancel] = useDebounce(
    () => {
      if (searchText.length > 0 && searchText !== queryParams?.search) {
        const newParams = {
          ...queryParams,
          search: searchText
        }
        setSearchParams(QueryString.stringify({ ...newParams }))
      }
    },
    500,
    [searchText]
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchText(event.target.value)
  }

  return (
    <Box>
      <Box mx={{ base: '0', md: '6' }} px={{ base: '3', md: '0' }} py={3}>
        <HStack flexDirection={{ base: 'column', sm: 'row' }} spacing={6} justifyContent={'space-between'}>
          <HStack flex={1} width={'100%'} maxW={'400px'}>
            <InputGroup>
              <InputLeftElement height={9} pointerEvents='none'>
                <TbSearch color='gray.300' />
              </InputLeftElement>
              <Input
                height={9}
                value={searchText}
                placeholder='Rechercher'
                onChange={onSearchChange}
              />
            </InputGroup>
          </HStack>

          <HStack flex={1} width={'100%'} maxW={'400px'}>
            <HStack spacing={1}>
              <Text><MdOutlineLocationOn /></Text>
              <Text display={['none', 'none', 'block']}>Emplacement</Text>
            </HStack>
            <StructureSelectHeader
              width={'100%'}
              flex={1}
              structureId={`${queryParams.structure_id}`}
              onStructureChange={onStructureChange}
            />
          </HStack>

        </HStack>
      </Box>
      <BoxSection py={0} px={0} bg={'bg.surface'}>
        <Stack spacing="5" px={3}>
          <VehiculesListToolbar isLoading={isLoading} data={data} params={queryParams} />
          <HStack flexDirection={{ base: 'column', md: 'row' }} spacing={0} pb={3} alignItems={'stretch'}>
            <Box flex={1}>
              <VStack spacing={3} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
                <Box p={0} borderBottomWidth={'1px'} borderBottomColor={'bg.muted'} alignItems={'stretch'} justifyContent={'stretch'}>
                  <VStack spacing={3} alignItems={'stretch'}>
                    {data?.data?.map(data => (
                      <VehiculeCard orgId={orgId ?? ''} queryParams={queryParams} data={data} key={data.id} />
                    ))}
                    {data?.data.length === 0 && <VStack p={3} pb={6}>
                      <Text color={'fg.subtle'} textAlign={'center'}>Aucun résultat</Text>
                    </VStack>}
                  </VStack>
                </Box>
              </VStack>
            </Box>
          </HStack>
        </Stack>
      </BoxSection>
    </Box>
  );
})
