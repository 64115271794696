
import { Button, HStack, Stack, StackDivider, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import UserEditForm from '@/components/UserEditForm/UserEditForm';
import { useUser } from '@/hooks/auth';
import { CreateUserAttributes, UserAttributes } from '@/interfaces/user';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { accountRoute } from '@/constants/routes';
import { TbArrowLeft } from 'react-icons/tb';


export const UserEditPage = () => {
  const { data: user, refetch } = useUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate()
  const { orgId } = useParams()

  const { mutateAsync, data } = useMutation({
    mutationFn: (userPayload: Partial<UserAttributes>) => updateUser(user?.attributes.id || '', userPayload),
  })

  const onUserChange = async (values: CreateUserAttributes) => {
    try {
      if (data === undefined) {
        await mutateAsync(values);
        await queryClient.invalidateQueries({ queryKey: ['authenticated-user'] })
        await refetch()
        navigate(accountRoute(orgId), { replace: true, state: "{updated: true}" })
      }
    } catch (err: unknown) {
      console.error(err)
      // setError(err);
    }
  }

  return (
    <PageWithTitle
      title="Modifier mes informations"
      actions={[
      ]}
    >

      <BoxSection py={6}>
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Général
            </Text>
            <Text fontSize="sm" color="muted">
              Paramètres du compte
            </Text>
          </Stack>
          {user?.attributes && <UserEditForm user={user?.attributes} onSubmit={onUserChange} />}


          <HStack w={'100%'} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>

            <Button
              leftIcon={<TbArrowLeft />}
              variant="outline"
              w={'100%'}
              as={Link}
              to={accountRoute(orgId)}
            >
              Retour
            </Button>
          </HStack>

        </Stack>
      </BoxSection>
    </PageWithTitle>
  );
}

export default UserEditPage;