
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, HStack, ListItem, OrderedList, Stack, Link, Text } from '@chakra-ui/react';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { useLogout, useUser } from '@/hooks/auth';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { reservationsRoute, reservationsSearchRoute, vehiculesRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { TbCalendar, TbCarCrash, TbSearch } from 'react-icons/tb';


export const HelpPage = () => {
  const { data: user, refetch } = useUser();
  const logout = useLogout()
  const { orgId } = useParams();
  const navigate = useNavigate()
  const { ability, getSelectedOrganization } = useOrganizations()

  const org = getSelectedOrganization()

  return (
    <PageWithTitle
      title="Centre d'aide"
      actions={[]}
    >
      <BoxSection py={6}>
        <Stack spacing="5">
          <Box>
            Vous trouverez ici tous les guides nécessaires a la bonne utilisation de PIRO.
          </Box>
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <HStack>
                    <Heading size={['2em', 'xs']}>
                      <TbCarCrash />
                    </Heading>
                    <Heading size={['2em', 'xs']}>
                      Je suis en panne / J'ai eu un accident
                    </Heading>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                En cas d'incident sur votre véhicule pendant votre mission, vous pouvez <a href="tel:0687858070"><b>appeler le cadre territorial d'astreinte au 06 87 85 80 70</b></a>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <HStack>
                    <Heading size={['2em', 'xs']}>
                      <TbSearch />
                    </Heading>
                    <Heading size={['2em', 'xs']}>
                      Réserver un véhicule (peu importe lequel)
                    </Heading>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text pb={3}>Pour réserver un véhicule:</Text>
                <OrderedList spacing={1}>
                  <ListItem>
                    Faites une recherche de véhicule en cliquant sur <Link variant={'link'} as={RouterLink} to={reservationsSearchRoute(orgId)}>le bouton "Réserver"</Link> dans le menu
                  </ListItem>
                  <ListItem>
                    Sélectionnez la structure (le lieu ou se trouve votre véhicule), et les dates de début et de fin de votre trajet.
                  </ListItem>
                  <ListItem>
                    Cliquez sur "Rechercher".
                  </ListItem>
                  <ListItem>
                    Une liste de véhicules disponibles sur vos dates s'affiche, sélectionnez le véhicule souhaité en cliquant dessus.
                  </ListItem>
                  <ListItem>
                    Cliquez sur "Réserver".
                  </ListItem>
                  <ListItem>
                    Choisissez la mission dans le menu déroulant, ou si elle n'existe pas, sélectionnez "Nouvele mission" et remplissez les champs requis.
                  </ListItem>
                  
                </OrderedList> 
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <HStack>
                    <Heading size={['2em', 'xs']}>
                      <TbSearch />
                    </Heading>
                    <Heading size={['2em', 'xs']}>
                      Réserver un véhicule spécifique
                    </Heading>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text pb={3}>Pour réserver un véhicule spécifique:</Text>
                <OrderedList spacing={1}>
                  <ListItem>
                    Parcourez les véhicules en cliquant sur <Link variant={'link'} as={RouterLink} to={vehiculesRoute(orgId)}>le bouton "Véhicules"</Link> dans le menu
                  </ListItem>
                  <ListItem>
                    Sélectionnez le véhicule souhaité.
                  </ListItem>
                  <ListItem>
                    Si vous ne trouvez pas votre véhicule, assurez vous de sélectionner la bonne structure dans le menu déroulant en haut de la page, et que le véhicule n'est pas hors-service.
                  </ListItem>
                  <ListItem>
                    Cliquez sur "Réserver".
                  </ListItem>
                  <ListItem>
                    Choisissez la mission dans le menu déroulant, ou si elle n'existe pas, sélectionnez "Nouvele mission" et remplissez les champs requis.
                  </ListItem>
                  
                </OrderedList> 
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <HStack>
                    <Heading size={['2em', 'xs']}>
                      <TbCalendar />
                    </Heading>
                    <Heading size={['2em', 'xs']}>
                      Consulter les véhicules réservés
                    </Heading>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text pb={3}>Pour consulter les véhicules réservés</Text>
                <OrderedList spacing={1}>
                  <ListItem>
                    Accedez au calendrier en cliquant sur <Link variant={'link'} as={RouterLink} to={reservationsRoute(orgId)}>le bouton "Calendrier"</Link> dans le menu.
                  </ListItem>
                </OrderedList> 
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <HStack>
                    <Heading size={['2em', 'xs']}>
                      <TbCalendar />
                    </Heading>
                    <Heading size={['2em', 'xs']}>
                      Annuler une réservation
                    </Heading>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text pb={3}>Pour annuler une réservation de véhicule</Text>
                <OrderedList spacing={1}>
                  <ListItem>
                    Accedez au calendrier en cliquant sur <Link variant={'link'} as={RouterLink} to={reservationsRoute(orgId)}>le bouton "Calendrier"</Link> dans le menu.
                  </ListItem>
                  <ListItem>
                    Trouvez la réservation en parcourant le calendrier.
                  </ListItem>
                  <ListItem>
                    Cliquez dessus.
                  </ListItem>
                  <ListItem>
                    Cliquez sur "Annuler la réservation".
                  </ListItem>
                </OrderedList> 
              </AccordionPanel>
            </AccordionItem>

          </Accordion>


        </Stack>
      </BoxSection>
    </PageWithTitle>
  );
}

export default HelpPage;