
import {
  Badge,
  BadgeProps,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react'

import { STATES_LABELS, StateKey } from '@/utils/states';

export interface StateBadgeProps extends BadgeProps {
  state: StateKey | undefined
}

const StateBadge = ({ state, ...props }: StateBadgeProps) => {
  return state ? (
    <Badge
      variant='subtle'
      borderRadius={2}
      textTransform="capitalize"
      colorScheme={STATES_LABELS[state].color}
      backgroundColor={`${STATES_LABELS[state].color}.50`}
      border={`1px solid`}
      borderColor={`${STATES_LABELS[state].color}.50`}
      {...props}
    >
      <HStack>
        <Icon color={`${STATES_LABELS[state].color}.800`} as={STATES_LABELS[state].icon} />
        <Text color={`${STATES_LABELS[state].color}.800`}>{STATES_LABELS[state].label}</Text>
      </HStack>
    </Badge>
  ) : <></>
}

export default StateBadge
