import {
  HStack,
  IconButton,
  TableProps,
  Text,
  Link,
  Tag
} from '@chakra-ui/react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from '@tanstack/react-table'
import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { RideAttributes, RideAttributesMeta } from '@/interfaces/ride'
import { RecordId } from '@/interfaces/id'
import { formatDateShort, formatDistanceBetweenShort } from '@/utils/datesFormat'
import { parseISO } from 'date-fns'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { rideRoute, vehiculeSummaryRoute } from '@/constants/routes'
import { CollectionTable } from '@/components/CollectionTable/CollectionTable'
import { useOrganizations } from '@/contexts/useOrganizationsContext'
import { truncate } from '@/utils/string'
import { RIDE_USABLE_LABEL } from '@/utils/rides'
import { colorsForCode } from '@/utils/missions'
import { getPaginationHandler } from '@/utils/table'
import { StatusTagForDates } from '@/components/StatusTagForDates/StatusTagForDates'
import ButtonWithAlert from '@/components/ButtonWithAlert/ButtonWithAlert'


const columnHelper = createColumnHelper<ApiResource<RideAttributes, RideAttributesMeta>>()


export interface RideTableProps extends TableProps {
  data: ApiCollectionResponse<RideAttributes, unknown, RideAttributesMeta>
  onDelete: (rideId: RecordId) => Promise<void>
  onEdit: (rideId: RecordId) => Promise<void>
  onPaginationChange?: (page: number) => void | undefined
}

export const RideTable = ({ data, onDelete, onEdit, onPaginationChange, ...props }: RideTableProps) => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()

  const columns = [
    // columnHelper.accessor(row => row.attributes.id, {
    //   id: 'name',
    //   cell: info => <Link as={RouterLink} to={rideSummaryRoute(orgId, info.row.original.attributes.id)}>{info.getValue()}</Link>,
    //   header: () => <span>Id</span>,
    //   footer: info => info.column.id,
    // }),
    // columnHelper.accessor('attributes.current_state', {
    //   header: 'État',
    //   cell: info => <StateBadge state={info.getValue()} />,
    //   footer: info => info.column.id,
    // }),
    columnHelper.accessor('meta.mission.name', {
      header: 'Trajet',
      cell: info =>
        ability?.can('show', 'ride', info.row.original.attributes) ? (
        <Link
          as={RouterLink}
          to={rideRoute(orgId, info.row.original.attributes.id)}
          color="fg.muted"
        >{info.getValue()}</Link>) : (
            <Text title={info.getValue()} _hover={{ cursor: 'not-allowed'}}>{truncate(info.getValue(), 20)}</Text>
        ),
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: "status",
      header: 'Status',
      cell: info => <StatusTagForDates beginAt={info.row.original.attributes.begin_at} endAt={info.row.original.attributes.end_at} />,
      enableSorting: false,
    }),

    columnHelper.accessor('meta.user.full_name', {
      header: 'Conducteur',
      cell: info => <Text title={info.getValue()}>{truncate(info.getValue(), 20)}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('meta.equipment.name', {
      header: () => <span>Véhicule</span>,
      cell: info => (
        <Link
          as={RouterLink}
          to={vehiculeSummaryRoute(orgId, info.row.original.attributes.equipment_id)}
          color="fg.muted"
        >{info.getValue()}</Link>
      ),
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.begin_at', {
      header: 'Début',
      cell: info => <Text>{info.getValue() && formatDateShort(parseISO(info.getValue()))}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.end_at', {
      header: 'Fin',
      cell: info => <Text>{info.getValue() && formatDateShort(parseISO(info.getValue()))}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: 'km_total',
      header: 'Total',
      cell: (props) => (<HStack spacing="1">
        {props.row.original.attributes.km_before && props.row.original.attributes.km_after && (
          <Text>{props.row.original.attributes.km_after - props.row.original.attributes.km_before} km</Text>
        )}
      </HStack>),
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: 'duration',
      header: 'Durée',
      cell: (props) => (<HStack spacing="1">
        {props.row.original.attributes.begin_at && props.row.original.attributes.end_at && (
          <Text>{formatDistanceBetweenShort(parseISO(props.row.original.attributes.end_at), parseISO(props.row.original.attributes.begin_at))}</Text>
        )}
      </HStack>),
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.usable', {
      header: 'État',
      cell: info => <Text>{RIDE_USABLE_LABEL[info.getValue()]}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.km_before', {
      header: 'Km départ',
      cell: info => <Text>{info.getValue()} km</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.km_after', {
      header: 'Km arrivée',
      cell: info => <Text>{info.getValue()} km</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('meta.mission.mission_type', {
      header: 'Code',
      cell: info => {

        const {
          bg: eventBg,
          border: eventBorder,
          fg: eventFg,
          subtle: eventSubtle
        } = colorsForCode(info.row.original.meta?.mission.mission_type)

        return info.row.original.meta?.mission.mission_type && ability?.can('show', 'ride', info.row.original.attributes) ? (
          <Tag
            color={eventFg} bgColor={eventBg}
          >{info.getValue()}</Tag>) : (
          <Text title={info.getValue()}>{truncate(info.getValue(), 20)}</Text>
        )
      },
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => (<HStack spacing="1">
        { ability?.can('update', 'ride', props.row.original?.attributes) && (
          <IconButton variant={'link'} icon={<FiEdit2 />} onClick={() => onEdit(props.row.original.id)} aria-label="Edit" />
        )}
        { ability?.can('delete', 'ride', props.row.original?.attributes) && (
          <ButtonWithAlert buttonText='' onAccept={() => onDelete(props.row.original.id)} variant={'link'} leftIcon={<FiTrash2 />} aria-label="Delete" />
        )}
      </HStack>),
      footer: info => info.column.id,
    })
  ]

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data.meta?.pages ?? -1,
    state: {
      pagination: {
        pageIndex: data?.meta?.page ? data?.meta?.page - 1 : 0,
        pageSize: data?.meta?.items ?? 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: getPaginationHandler(data, onPaginationChange),
  })

  return (<CollectionTable table={table} {...props} />)
}
