
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { Box, Button, Divider, HStack, Kbd, Tag, Text, VStack, useColorModeValue, useToast } from '@chakra-ui/react';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams, useNavigate, Link } from 'react-router-dom';
import StateBadge from '@/components/StateBadge/StateBadge';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { EquipmentActionTakeoutButton } from '@/screens/EquipmentActionButton/Takeout/Button';
import { useUser } from '@/hooks/auth';
import { useEffect, useMemo } from 'react';
import { goEquipmentFinishRideRoute, goEquipmentSetupMissionRoute } from '@/constants/routes';
import { TbBook, TbExclamationCircle, TbHandStop, TbTimeline, TbTimelineEvent, TbTruck } from 'react-icons/tb';
import { formatDistanceShort } from '@/utils/datesFormat';
import { differenceInMinutes, parseISO } from 'date-fns';
import { useEquipmentReservation } from '@/hooks/useEquipmentReservation';
import { BoxForMissionStatus } from './BoxForMissionStatus';
import { closeMission } from '@/api/mission';
import EndlessRoad from '@/components/EndlessRoad/EndlessRoad';
import { removeEquipmentFromMission } from '@/api/missionEquipment';
import { BoxForNextMissionStatus } from './BoxForNextMissionStatus';
import { FaTruckMoving } from 'react-icons/fa';
import TruckIconRunning from '@/components/TruckIconRunning/TruckIconRunning';
import ButtonWithAlert from '@/components/ButtonWithAlert/ButtonWithAlert';
import { MdDangerous } from 'react-icons/md';
import { WarningIcon } from '@chakra-ui/icons';
import { forceFinishRide } from '@/api/ride';
import VehiculeHistory from '../Vehicules/VehiculeHistory';
import { EquipmentDetailsModal } from './EquipmentDetailsModal';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const TakeoutEquipmentSummary = () => {

  const { equipmentId, orgId } = useParams()
  const { data: user } = useUser();
  const toast = useToast()
  const navigate = useNavigate()
  const { ability } = useOrganizations()

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { data, refetch } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })

  const { mutateAsync: doCloseMission } = useMutation({
    mutationFn: (missionId: RecordId) => closeMission(missionId)
  })

  const { mutateAsync: doCancelReservation } = useMutation({
    mutationFn: (missionEquipmentId: RecordId) => removeEquipmentFromMission(missionEquipmentId)
  })

  const { mutateAsync: doForceFinishRide } = useMutation({
    mutationFn: (rideId: RecordId) => forceFinishRide(rideId)
  })

  const onTakeout = (equipmentId: RecordId, missionId: RecordId) => {
    console.log("Takeout !")
    navigate(goEquipmentSetupMissionRoute(orgId, equipmentId, missionId))
  }

  const onClose = async (equipmentId: RecordId, missionId: RecordId) => {
    console.log("Close mission !")
    await doCloseMission(missionId)
    await refetch()
  }

  const onCancel = async (missionEquipmentId: RecordId) => {
    console.log("Cancel reservation !")
    await doCancelReservation(missionEquipmentId)
    await refetch()
  }

  const onForceFinishRide = async (rideId: RecordId) => {
    console.log("Force finish ride !")
    try {
      const r = await doForceFinishRide(rideId)
    } catch (error) {
      toast({ title: error.error, status: 'error', isClosable: true, duration: 5000 })
    }
    await refetch()
  }

  const { data: equipment } = (data || {})

  const canMakeReservation = useMemo(() => ability?.can('reserve', 'equipment', equipment?.attributes), [ability, equipment?.attributes])
  const {
    activeMissions,
    activeRides,
    hasActiveMission,
    hasActiveRide,
    hasActiveMissionForUser,
    hasActiveRideForUser,
    currentActiveMissionForUser,
    currentActiveRideForUser,
    hasActiveMissionForOtherUser,
    hasActiveRideForOtherUser,
    upcomingMissionForOtherUser,
  } = useEquipmentReservation(user, equipment?.meta)

  // Debug @TODO to remove
  useEffect(() => {
    console.log({ canMakeReservation, hasActiveMission, hasActiveMissionForUser, hasActiveMissionForOtherUser })
  }, [canMakeReservation, hasActiveMission, hasActiveMissionForUser, hasActiveMissionForOtherUser])

  return ( 
    <VStack
      borderRadius={6}
      px={{ base: '4', lg: '6' }} 
      py={3}
      flexGrow={1}
      flexShrink={0}
      borderColor={'transparent'}
      borderWidth={1}
      flexBasis={{ base: '100%', md: '250px' }}
      spacing={6}
      alignItems={'stretch'}
    >
        <VStack spacing={0} flex={1} alignItems={'stretch'}>
          <Box
            px={3}
            py={3}
            bg={useColorModeValue('white', 'gray.800')}
            borderColor={'bg.accent'}
            borderWidth={1}
            borderRadius={5}
            textAlign={'center'}
          >
            <VStack spacing={6} flex={1} alignItems={'stretch'} justifyContent={'space-around'}>
              <VStack spacing={3} flex={1} alignItems={'stretch'}>
                <HStack textAlign={'center'} flex={1} alignItems={'center'} justifyContent={'center'}>
                  <Text>{equipment?.attributes.manufacturer} {equipment?.attributes.model}</Text>
                  <Text>
                  <Kbd>{equipment?.attributes.serial}</Kbd>
                  </Text>
                </HStack>
                <HStack flex={1} alignItems={'stretch'} justifyContent={'center'}>
                  {equipment?.attributes.seats && <Tag>{equipment?.attributes.seats} places</Tag>}
                  {equipment?.meta?.equipment_type.name && <Tag>{equipment?.meta?.equipment_type.name}</Tag>}
                  <StateBadge minW={'100px'} borderRadius={4} variant={'solid'} size='lg' state={equipment?.attributes?.current_state} />
                  <StateBadge minW={'100px'} borderRadius={4} variant={'solid'} size='lg' state={equipment?.attributes?.current_availability} />
                </HStack>
                <HStack flex={1} alignItems={'stretch'} justifyContent={'center'}>
                  {equipment && <EquipmentDetailsModal data={equipment} variant={'ghost'} />}
                </HStack>
              </VStack>


                {/* Box en route depuis X... */}
                {hasActiveRideForUser && currentActiveRideForUser && (<VStack alignItems={'stretch'}>
                  <HStack py={3} gap={3} flex={1} alignItems={'center'} justifyContent={'center'}>
                    <Box color={'red.600'}><TruckIconRunning /></Box>
                    {differenceInMinutes(new Date(), parseISO(currentActiveRideForUser.begin_at || '')) < 1 ?
                      (<Text textAlign={'left'} fontSize={'large'}>En route</Text>) :
                      (<Text textAlign={'left'} fontSize={'large'}>En route depuis {formatDistanceShort(parseISO(currentActiveRideForUser.begin_at || ''), { addSuffix: false })}</Text>)
                    }
                  </HStack>
                  <HStack flex={1} mt={3} alignItems={'stretch'} justifyContent={'space-between'}>
                    <Button
                      as={Link}
                      to={goEquipmentFinishRideRoute(orgId, equipmentId, currentActiveRideForUser.mission_id, currentActiveRideForUser.id)}
                      flex={1}
                      colorScheme='brand'
                      data-button="finish-ride"
                      leftIcon={<TbHandStop />}
                    >Terminer le trajet</Button>
                  </HStack>
                </VStack>)}

                {/* Box un autre en route depuis X... */}
                {!hasActiveRideForUser && hasActiveRideForOtherUser && (<>
                  <Box>
                    <HStack py={3} gap={3} alignItems={'center'} justifyContent={'center'}>
                      <Box color={'red.600'}><TruckIconRunning /></Box>
                      <Text textAlign={'left'} data-text="ride-exists" fontSize={'large'}>
                        <span>{activeRides[0].user_full_name} est en route {' '}</span>
                        {differenceInMinutes(new Date(), parseISO(activeRides[0].begin_at || '')) >= 1 && (<span>depuis {formatDistanceShort(parseISO(activeRides[0].begin_at || ''), { addSuffix: false })}</span>)}
                      </Text>
                    </HStack>
                    <HStack flex={1} mt={3} alignItems={'stretch'} justifyContent={'space-between'}>

                      <ButtonWithAlert
                        onAccept={() => activeRides[0].id && onForceFinishRide(activeRides[0].id)}
                        buttonText='Forcer la fin du trajet'
                        w={'100%'}
                        colorScheme='red'
                        variant={'outline'}
                        dialogText={`Le trajet de ${activeRides[0].user_full_name} sera terminé`}
                        // color={'red.600'}
                        isDisabled={activeRides[0].id === undefined}
                        data-button="force-finish-ride"
                        leftIcon={<WarningIcon />}
                      />
                    </HStack>

                  </Box>
                </>)}
            </VStack>
        </Box>

      </VStack>

      <VStack spacing={0} flex={1} alignItems={'stretch'}>
        <Box
          // px={3}
          // py={3}
          bg={useColorModeValue('white', 'gray.800')}
          borderColor={'bg.accent'}
          borderWidth={1}
          borderRadius={5}
          textAlign={'center'}
        >
          <VStack spacing={3} flex={1} alignItems={'stretch'}>
            <VStack spacing={0} flex={1} alignItems={'stretch'}>
              <BoxForMissionStatus
                onCancel={onCancel}
                onClose={onClose}
                orgId={orgId || ''}
                equipmentId={equipmentId || ''}
                user={user} 
                meta={equipment?.meta}
              />

              {canMakeReservation && <>
                {!hasActiveMission && !hasActiveRide && (
                  <HStack flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
                    <EquipmentActionTakeoutButton
                      isDisabled={!canMakeReservation}
                      equipmentId={equipment?.id || ''}
                      equipment={equipment?.attributes}
                      onTakeoutSuccess={onTakeout}
                      flex={1}
                      colorScheme='brand'
                    />
                  </HStack>
                )}
              </>}
            </VStack>
          </VStack>
        </Box>
        
      </VStack>

      <VStack spacing={0} flex={1} alignItems={'stretch'}>
        <Box
          // px={3}
          // py={3}
          bg={useColorModeValue('white', 'gray.800')}
          borderColor={'bg.accent'}
          borderWidth={1}
          borderRadius={5}
          textAlign={'center'}
        >
          <VStack spacing={0} flex={1} alignItems={'stretch'}>
            <BoxForNextMissionStatus
              onCancel={onCancel}
              onClose={onClose}
              orgId={orgId || ''}
              equipmentId={equipmentId || ''}
              user={user}
              meta={equipment?.meta}
            />
          </VStack>
        </Box>
      </VStack>

      <VStack spacing={0} flex={1} alignItems={'stretch'}>
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          borderColor={'bg.accent'}
          borderWidth={1}
          borderRadius={5}
          textAlign={'center'}
        >
          <HStack p={3}>
            <Text fontSize={'large'} justifyContent={'center'} textAlign={'center'} fontWeight={'bold'}>
              <HStack>
                <Text color={'red.600'}><TbTimeline /></Text>
                <Text>Historique</Text>
              </HStack>
            </Text>
          </HStack>
          <Divider />
          <VehiculeHistory readonly />
        </Box>
      </VStack>
    </VStack>
  );
}

export default TakeoutEquipmentSummary;