
import {
  Box,
  Flex,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  FiHelpCircle,
} from 'react-icons/fi'
import {
  TbSearch,
  TbTruck
} from 'react-icons/tb'
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { MobileNavButton } from '../NavButton/NavButton'

import { useLogout, useUser } from '@/hooks/auth';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { reservationsSearchRoute, vehiculesRoute, accountRoute, helpRoute } from '@/constants/routes';
import { RiAccountCircleLine } from 'react-icons/ri'
import QueryString from 'qs'



export const Navbar = () => {

  const { data: user } = useUser()
  const logout = useLogout()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { organizations, getSelectedOrganization, ability } = useOrganizations()

  const currentOrganization = getSelectedOrganization()

  const preferedStructure = user?.meta.user_structures && user?.meta.user_structures[0]

  const vehiculesUrl = vehiculesRoute(currentOrganization?.id) + '?' + QueryString.stringify({ usable: true, structure_id: preferedStructure?.structure_id })

  const onLogout = () => {
    logout.mutate({})
    navigate('/login')
  }

  return (
    <Box
      width="full"
      borderTop={'1px solid'}
      borderTopColor={'bg.muted'}
      bg="white"
      px={1}
      py={3}
      overflowX={'auto'}
      boxShadow={useColorModeValue('sm', 'sm-dark')}
    >
      <Flex justify="space-between">

        {currentOrganization && ability && (
          <HStack spacing="1" alignItems={'stretch'} flex={1}>

            {/* {ability?.can('reserve', 'organization') && preferedStructure && (
              <StartNowModal buttonProps={{ w: "100%", justifyContent: "center" }}>
                <HStack spacing="0">
                  <VStack spacing="0">
                    <Icon as={TbFlag2} boxSize="6" color="subtle" />
                    <Text>Partir</Text>
                  </VStack>
                </HStack>
              </StartNowModal>
            )} */}

            {ability?.can('reserve', 'organization') && preferedStructure && (
              <MobileNavButton label="Réserver" as={RouterLink} to={reservationsSearchRoute(currentOrganization?.id)} icon={TbSearch} />
            )}

            {/* {ability?.can('reserve', 'organization') && preferedStructure && (
              <MobileNavButton label="Calendrier" as={RouterLink} to={reservationsRoute(currentOrganization?.id)} icon={TbCalendar} />
            )} */}

            {ability?.can('list', 'equipment') && (<>
              <MobileNavButton label="Véhicules" as={RouterLink} to={vehiculesUrl} icon={TbTruck} />
            </>)}
            <MobileNavButton label="Compte" as={RouterLink} to={accountRoute(getSelectedOrganization()?.id)} icon={RiAccountCircleLine} />

            <MobileNavButton as={RouterLink} to={helpRoute(currentOrganization?.id)} label="Aide" icon={FiHelpCircle} />


          </HStack>
        )}
      </Flex>
    </Box>
  )
}