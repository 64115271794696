// import "instantsearch.css/themes/algolia-min.css";

import { useParams, Link as RouterLink } from 'react-router-dom';
import { Button, Checkbox, HStack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';

import {
  useHits} from "react-instantsearch";
import { equipmentsRoute } from "@/constants/routes";
import { ClearRefinements } from '@/components/ClearRefinements/ClearRefinements';
import { MdClose } from 'react-icons/md';
import { SortBy } from '@/components/SortBy/SortBy';
import { SearchBox } from '@/components/SearchBob/SearchBox';
import { TbArrowLeft, TbCheckbox } from 'react-icons/tb';
import { useEffect, useMemo } from 'react';
import { EquipmentActionMoveButton } from '@/screens/EquipmentActionButton/Move/Button';
import { EquipmentActionReservationButton } from '@/screens/EquipmentActionButton/Reservation/Button';
import { EquipmentActionButton } from '@/screens/EquipmentActionButton/Action/Button';


export interface EquipmentsListToolbarProps {
  refinementList?: {[attribute: string]: string[]}
  checkedItems: (string | number)[]
  setCheckedItems: React.Dispatch<React.SetStateAction<(string | number)[]>>
  selectionEnabled: boolean
  setSelectionEnabled: (v: boolean) => void
}
export const EquipmentsListToolbar = ({
  refinementList,
  checkedItems,
  setCheckedItems,
  selectionEnabled,
  setSelectionEnabled,
}: EquipmentsListToolbarProps) => {

  const { orgId } = useParams()
  const { hits } = useHits()
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const toggleSelectionMode = () => {
    if (selectionEnabled) {
      setCheckedItems([])
    }
    setSelectionEnabled(!selectionEnabled)
  }

  const selectAll = () => {
    if (checkedItems.length === hits.length) {
      setCheckedItems([])
    } else {
      setCheckedItems(hits.map((e: any) => e.id))
    }
  }

  const allChecked = useMemo(() => {
    // console.log({ checkedItems  })
    return checkedItems.length === hits.length
  }, [checkedItems, hits])

  const someChecked = useMemo(() => {
    // console.log({ checkedItems  })
    return checkedItems.length > 0
  }, [checkedItems])

  return (
    <VStack spacing={0} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
      <HStack flexDirection={{ base: 'column', sm: 'row' }} p={3} borderBottomWidth={'1px'} borderBottomColor={'bg.muted'} spacing={6} alignContent={'flex-start'} alignItems={'center'} justifyContent={'space-between'}>
        
        {refinementList && (<HStack>
          <Button as={RouterLink} to={equipmentsRoute(orgId)} size={'sm'} leftIcon={<TbArrowLeft />}>Tous les véhicules</Button>
        </HStack>)}
        
        {isDesktop && <HStack>
          {/* <Text minW={'70px'} color={'fg.muted'} pb={1} fontSize='md'>Trier par</Text> */}
          <SortBy
            items={[
              { value: "vehicules", label: "Défaut" },
              {
                value: "vehicules:identifier:asc",
                label: "Identifiant"
              },
              {
                value: "vehicules:equipment_type:asc",
                label: "Véhicule"
              },
              {
                value: "vehicules:current_location_short_name:asc",
                label: "Emplacement"
              },
              {
                value: "vehicules:structure_short_name:asc",
                label: "Structure"
              },
            ]}
          />
        </HStack>}


        <HStack>
          <SearchBox />
        </HStack>
        <HStack alignContent={'baseline'} justifyContent={'flex-end'}>
          <ClearRefinements excludedAttributes={refinementList ? Object.keys(refinementList) : []} />
          {!selectionEnabled && <Button size={'sm'} onClick={toggleSelectionMode} leftIcon={<TbCheckbox />}>Sélectionner</Button>}
        </HStack>

      </HStack>

      {selectionEnabled && (
      
        <HStack flexDirection={{ base: 'column', md: 'row' }} p={3} borderBottomWidth={'1px'} borderBottomColor={'bg.muted'} spacing={6} alignContent={'flex-start'} alignItems={'center'} justifyContent={'space-between'}>

          <HStack alignItems={'center'}>
            <Checkbox size={'sm'} isIndeterminate={someChecked && !allChecked} isChecked={someChecked} onChange={selectAll}>
              <Text minW={'70px'} pb={1} fontSize='md'>Sélectionner tout</Text>
            </Checkbox>
            <Text minW={'70px'} color={'fg.muted'} pb={1} fontSize='md'>{checkedItems.length} sélectionnés</Text>
          </HStack>

          <HStack>
            <EquipmentActionReservationButton variant='outline' isDisabled={!someChecked} size={'sm'} equipmentIds={checkedItems}/>
            <EquipmentActionButton variant='outline' isDisabled={!someChecked} size={'sm'} equipmentIds={checkedItems} />
            <EquipmentActionMoveButton variant='outline' isDisabled={!someChecked} size={'sm'} equipmentIds={checkedItems} />
            <Button size={'sm'} onClick={toggleSelectionMode} leftIcon={<MdClose />}>Annuler</Button>
          </HStack>
        </HStack>
      )}
    </VStack>
  );
}
