// import "instantsearch.css/themes/algolia-min.css";

import { Divider, HStack, Heading, Kbd, Tag, Text, VStack, useColorModeValue } from '@chakra-ui/react';

import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResource } from '@/utils/api';
import { useLazyImage } from '@/hooks/useLazyImage';
import { HiWrench } from 'react-icons/hi2';
import { parseAndFormatDateShort } from '@/utils/datesFormat';
import { TbCheck, TbEye, TbGauge, TbId, TbLocation, TbLocationPin, TbPin, TbPinFilled } from 'react-icons/tb';
import StateBadge from '@/components/StateBadge/StateBadge';
import ValueWithLabel from '@/components/ValueWithLabel/ValueWithLabel';
import ValueWithBorderedLabel from '@/components/ValueWithLabel/ValueWithBorderedLabel';
import { MdOutlinePin } from 'react-icons/md';
import { BsPeople, BsPeopleFill } from 'react-icons/bs';


export interface VehiculeDetailsProps {
  data: ApiResource<EquipmentAttributes, EquipmentAttributesMeta>
}

export const VehiculeDetails = ({ data }: VehiculeDetailsProps) => {

  const { attributes: vehicule, meta } = data

  const { imageUrl, isImageLoaded } = useLazyImage(vehicule.image_url, vehicule.image_thumb_url)
  const color = useColorModeValue('black', 'white')

  return (
    <VStack alignItems={'stretch'} spacing={6} width={'100%'} alignContent={['flex-start', 'flex-start', 'center']}>
      <VStack flexWrap={'wrap'} flexDir={['column', 'column', 'row']} spacing={3} flex={1} justifyContent={'center'} alignContent={['flex-start', 'flex-start', 'center']} alignItems={['flex-start', 'flex-start', 'center']}>
        {(vehicule.manufacturer || vehicule.model) && <Text>{vehicule.manufacturer} {vehicule.model}</Text>}
        <HStack flexWrap={'wrap'} flex={1} alignItems={'stretch'} justifyContent={['center', 'center', 'flex-start']}>
          {/* {vehicule.seats && <Tag>{vehicule.seats} places</Tag>} */}
          {meta?.equipment_type.name && <Tag>{meta?.equipment_type.name}</Tag>}
          <StateBadge minW={'100px'} borderRadius={4} size='md' state={vehicule?.current_state} />
          <StateBadge minW={'100px'} borderRadius={4} size='md' state={vehicule?.current_availability} />
        </HStack>
      </VStack>
      <Divider />
      <VStack pb={3} align={'flex-start'} spacing={6} flexWrap={'wrap'} width={'100%'}>
        <ValueWithLabel additionalProps={{minW: '170px'}} label="Immatriculation" icon={<TbId />}>
          <Kbd>{vehicule.serial}</Kbd>
        </ValueWithLabel>
        <Divider />
        <HStack flexWrap={'wrap'} width={'100%'} spacing={3} flex={1} justifyContent={'flex-start'} alignItems={['flex-start', 'flex-start', 'center']}>
          <ValueWithLabel additionalProps={{minW: '170px'}} label="Emplacement" icon={<TbPinFilled />}>
            <Text>{meta?.current_location?.short_name}</Text>
          </ValueWithLabel>
          <ValueWithLabel additionalProps={{minW: '170px'}} label="Rattaché à" icon={<TbPin />}>
            <Text>{meta?.structure.short_name}</Text>
          </ValueWithLabel>
        </HStack>
        <Divider />
        <HStack flexWrap={'wrap'} width={'100%'} spacing={3} flex={1} justifyContent={'flex-start'} alignItems={['flex-start', 'flex-start', 'center']}>
          <ValueWithLabel additionalProps={{minW: '170px'}} label="Kilométrage" icon={<TbGauge />}>
            <Text>{meta?.last_ride?.km_after ? `${meta?.last_ride?.km_after} km` : 'inconnu'}</Text>
          </ValueWithLabel>
          <ValueWithLabel additionalProps={{minW: '170px'}} label="Révision" icon={<HiWrench />}>
            <Text>Inconnue</Text>
          </ValueWithLabel>
          <ValueWithLabel additionalProps={{minW: '170px'}} label="Vérification" icon={<TbCheck />}>
            <Text>{parseAndFormatDateShort(vehicule.checked_at, 'Inconnu')}</Text>
          </ValueWithLabel>
          {vehicule.seats && <ValueWithLabel additionalProps={{minW: '170px'}} label="Capacité" icon={<BsPeopleFill />}>
            <Text>{`${vehicule.seats} places`}</Text>
          </ValueWithLabel>}
        </HStack>
      </VStack>
    </VStack>

  );

}
