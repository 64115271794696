import { darken, mode, StyleFunctionProps, transparentize } from '@chakra-ui/theme-tools'

const baseStyle = {
  ':focus:not(:focus-visible)': {
    boxShadow: 'none',
  },
  fontWeight: 'medium',
  borderRadius: 'lg',
}

const sizes = {
  lg: {
    fontSize: 'md',
  },
}

const variants = {
  primary: (props: StyleFunctionProps) =>
    props.theme.components['Button']['variants']['solid']({
      ...props,
      variant: 'solid',
      colorScheme: 'brand',
    }),
  'primary-on-accent': (props: StyleFunctionProps) =>
    props.theme.components['Button']['variants']['solid']({
      bg: 'brand.50',
      color: 'brand.600',
      _hover: { bg: 'brand.100' },
      _active: { bg: 'brand.100' },
    }),
  secondary: (props: StyleFunctionProps) =>
    props.theme.components['Button']['variants']['outline']({
      ...props,
      variant: 'outline',
      colorScheme: 'gray',
    }),
  'secondary-on-accent': {
    color: 'white',
    borderColor: 'brand.50',
    borderWidth: '1px',
    _hover: { bg: 'whiteAlpha.200' },
    _active: { bg: 'whiteAlpha.200' },
  },
  'white': {
    color: 'gray.600',
    bg: 'white',
    borderColor: 'gray.100',
    borderWidth: '1px',
    _hover: { borderColor: 'gray.200', bg: 'gray.100', color: 'gray.800' },
    _active: { borderColor: 'gray.200', bg: 'gray.100', color: 'gray.800' },
  },
  // outline: (props: StyleFunctionProps) => ({
  //   color: 'emphasized',
  //   bg: mode('white', 'gray.800')(props),
  //   _hover: {
  //     bg: mode(
  //       darken('gray.50', 1)(props.theme),
  //       transparentize('gray.700', 0.4)(props.theme),
  //     )(props),
  //   },
  //   _checked: {
  //     bg: mode('gray.100', 'gray.700')(props),
  //   },
  //   _active: {
  //     bg: mode('gray.100', 'gray.700')(props),
  //   },
  // }),
  orange: () => ({
    bg: 'secondary.100',
    color: 'secondary.800',
    _hover: { bg: 'secondary.200' },
    _active: { bg: 'secondary.200' },
  }),
  ghost: (props: StyleFunctionProps) => ({
    color: 'emphasized',
    _hover: {
      bg: mode(darken('gray.50', 1)(props.theme), darken('gray.700', 4)(props.theme))(props),
    },
    _active: {
      bg: mode(darken('gray.50', 1)(props.theme), darken('gray.700', 4)(props.theme))(props),
    },
    _activeLink: {
      bg: mode('gray.100', 'gray.700')(props),
    },
  }),
  'ghost-on-accent': (props: StyleFunctionProps) => ({
    color: 'brand.50',
    _hover: {
      bg: transparentize('brand.500', 0.67)(props.theme),
    },
    _activeLink: {
      color: 'white',
      bg: 'bg-accent-subtle',
    },
  }),
  sidebar: (props: StyleFunctionProps) => ({
    color: 'emphasized',
    _hover: {
      bg: mode(darken('gray.50', 1)(props.theme), darken('gray.700', 4)(props.theme))(props),
    },
    _active: {
      bg: mode(darken('gray.50', 1)(props.theme), darken('gray.700', 4)(props.theme))(props),
    },
    _activeLink: {
      bg: mode('red.50', 'red.800')(props),
      color: mode('red.800', 'red.50')(props),
    },
  }),
  'sidebar-mobile': (props: StyleFunctionProps) => ({
    color: 'default',
    _hover: {
      color: 'accent',
    },
    _activeLink: {
      color: 'accent',
    },
    _active: {
      color: 'accent',
    },
  }),
  tabnav: (props: StyleFunctionProps) => ({
    textAlign: 'center',
    bg: mode('gray.200', 'gray.700')(props),
    color: mode('gray.600', 'gray.200')(props),
    // borderRadius: '0px',
    _hover: {
      bg: mode(darken('gray.50', 1)(props.theme), darken('gray.700', 4)(props.theme))(props),
    },
    _active: {
      bg: mode(darken('gray.50', 1)(props.theme), darken('gray.700', 4)(props.theme))(props),
    },
    _activeLink: {
      fontWeight: 'bold',
      bg: mode('white', 'gray.500')(props),
      borderColor: mode('gray.100', 'white')(props),
      color: mode('gray.700', 'gray.200')(props),
    },
  }),
  link: (props: StyleFunctionProps) => {
    if (props.colorScheme === 'gray') {
      return {
        color: 'muted',
        _hover: {
          textDecoration: 'none',
          color: 'default',
        },
        _active: {
          color: 'default',
        },
      }
    }
    return {
      color: mode(`${props.colorScheme}.600`, `${props.colorScheme}.200`)(props),
      _hover: {
        color: mode(`${props.colorScheme}.700`, `${props.colorScheme}.300`)(props),
        textDecoration: 'none',
      },
      _active: {
        color: mode(`${props.colorScheme}.700`, `${props.colorScheme}.300`)(props),
      },
    }
  },
  'link-on-accent': () => {
    return {
      padding: 0,
      height: 'auto',
      lineHeight: 'normal',
      verticalAlign: 'baseline',
      color: 'brand.50',
      _hover: {
        color: 'white',
      },
      _active: {
        color: 'white',
      },
    }
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseStyle,
  variants,
  sizes,
}
