
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { Box, Divider, HStack, Heading, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { TbCalendar, TbFlag } from 'react-icons/tb';
import { parseAndFormatDateShort } from '@/utils/datesFormat';
import { parseEquipmentQueryParams } from '@/utils/equipment';
import { getMissionEquipmentsPlanningForEquipment, getMissionEquipmentsPlanningForEquipmentKey } from '@/api/missionEquipment';
import { MissionAsyncCard } from '@/screens/MissionAsyncCard/MissionAsyncCard';
import { VehiculeDetails } from '@/screens/VehiculeDetails/VehiculeDetails';
import ValueWithLabel from '@/components/ValueWithLabel/ValueWithLabel';
import { VehiculeReservationBoxButton } from '@/screens/EquipmentActionButton/VehiculeReservationBox/Button';
import EmptyList from '@/components/EmptyList/EmptyList';
import VehiculeHistory from './VehiculeHistory';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const VehiculeSummary = () => {

  const { orgId, equipmentId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false })

  const queryParams = parseEquipmentQueryParams(searchParams)
  const hasReservationDates = useMemo(() => queryParams.available_between?.start_date && queryParams.available_between?.end_date, [queryParams])

  const queryClient = useQueryClient()

  const [{ pageIndex, pageSize }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { data } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })

  const equipmentPlanningKey = [getMissionEquipmentsPlanningForEquipmentKey, equipmentId, queryParams]

  const { data: planning, refetch: refetchPlanning } = useNanuqQuery(
    equipmentPlanningKey,
    () => (equipmentId && hasReservationDates) ? getMissionEquipmentsPlanningForEquipment(
      equipmentId,
      pageIndex + 1,
      { by_period: { started_at: queryParams.available_between?.start_date, ended_at: queryParams.available_between?.end_date } }
    ) : null
  )

  const onReservation = () => {
    setSearchParams({})
  }

  const { data: equipment } = (data || {})
  const mobileBoxStyles = isMobile ? {
    m: 4,
    // mt: -12,
    spacing: 6,
    flex: 1,
    borderRadius: 9,
    justifyContent: 'center',
    bg: 'bg.surface',
  } : {
  }

  return (
    <Box>
      <VStack
        textAlign={'center'}
        p={0}
        alignItems={'stretch'}
        {...mobileBoxStyles}
      >

        {(hasReservationDates && equipment) && <VStack border={'1px solid'} bg={'bg.subtle'} borderColor={'border.default'} borderRadius={3} m={3} p={3} flex={1} alignItems={'stretch'}>
          <ValueWithLabel icon={<TbCalendar />} label="Votre demande de réservation">
            <VStack alignItems={'stretch'} width={'100%'}>
              <HStack spacing={1} flexWrap={'wrap'}>
                <Text>Du {parseAndFormatDateShort(queryParams.available_between?.start_date)}</Text>
                <Text>au {parseAndFormatDateShort(queryParams.available_between?.end_date)}</Text>
              </HStack>
              {planning?.data.length === 0 && (
                <HStack width={'100%'}>
                  <Text color='green'>Le véhicule est disponible !</Text>
                  <VehiculeReservationBoxButton
                    equipmentId={data?.data?.id || ''}
                    buttonLabel="Réserver le véhicule"
                    queryParams={queryParams}
                    equipment={data?.data.attributes}
                    variant={'outline'}
                    size={'sm'}
                    colorScheme='brand'
                    onReservationSuccess={onReservation}
                  />
                </HStack>
              )}
              {planning?.data.map(pla => <HStack width={'100%'}>
                <Text color='error'>Le véhicule est réservé !</Text>
              </HStack>)}
            </VStack>
          </ValueWithLabel>
        </VStack>}
        
        <HStack
          flexDirection={{ base: 'column', lg: 'row' }}
          justify={'flex-start'}
          alignContent={'stretch'}
          flexWrap={'wrap'}
          spacing={0}
          alignItems={{ base: 'stretch', lg: 'flex-start' }}
        >

          <VStack
            spacing={6}
            flex={1}
            p={[3, 3, 6]}
            minH={'500px'}
            justifyContent={'flex-start'}
            borderRight={{ base: '0px', lg: '1px solid' }}
            borderRightColor={{ base: 'transparent', lg: 'border.default' }}
            align={'flex-start'}
          >
            <Heading size={'xs'}>Détails</Heading>

            {equipment && <VehiculeDetails data={equipment} />}

            <Divider />

            <Heading size={'xs'}>Missions</Heading>

            {equipment?.meta && (
              <VStack width={'100%'} spacing={6} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
                <ValueWithLabel icon={<TbFlag />} label="Mission en cours">
                  <>
                    {equipment.meta.active_missions.map(m => <MissionAsyncCard missionId={m.id} orgId={orgId} key={m.id} />)}
                  </>

                  <EmptyList list={equipment.meta.active_missions}>
                    Aucune mission en cours
                  </EmptyList>

                </ValueWithLabel>
                <Divider />
                <ValueWithLabel icon={<TbFlag />} label="Prochaine mission">
                  {equipment.meta.next_mission?.name ? (
                    <MissionAsyncCard missionId={equipment.meta.next_mission.id} orgId={orgId} key={equipment.meta.next_mission.id} />
                  ) : (
                    <Text textAlign={'center'} color={'fg.muted'} fontSize='sm'>Aucune mission a venir</Text>
                  )}
                </ValueWithLabel>
              </VStack>)}
          </VStack>

          <VStack
            p={[3, 3, 6]}
            spacing={6}
            flex={1}
            // borderLeft={{ base: '0px', lg: '1px solid' }}
            // borderLeftColor={{ base: 'transparent', lg: 'border.default' }}
            justifyContent={'flex-start'}
            height={'100%'}
            align={'flex-start'}
          >
            <Heading size={'xs'}>Activité</Heading>

            {equipment && <VehiculeHistory />}
          </VStack>
        </HStack>
      </VStack>


      <Divider />
      <HStack
        flexWrap={'wrap'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        p={6}
      >

      </HStack>
    </Box>
  );
}

export default VehiculeSummary;