import { Box, HStack, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'

interface TabNavProps {
  children?: React.ReactNode
}

export const TabNav = ({ children }: TabNavProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true, lg: true })

  return (
    <Box
      mx={0}
      my={0}
      px={2}
      py={2}
      bg={useColorModeValue('gray.200', 'gray.800')}
      borderWidth="0px"
      border='1px solid'
      // borderTop='1px solid'
      borderColor={useColorModeValue('gray.200', 'gray.800')}
      borderRadius="md"
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
    >
      <HStack spacing={3} alignItems={isDesktop ? 'flex-start' : 'center'} justifyContent={isDesktop ? 'flex-start' : 'space-around'}>
        {children}
      </HStack>
    </Box>
  )
}