
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, HStack, VStack, useBreakpointValue, useToast } from '@chakra-ui/react';
import { EquipmentCalendar } from '@/components/EquipmentCalendar/EquipmentCalendar';
import { addEquipmentToMission, getMissionEquipmentsPlanningForEquipmentKey, getMissionEquipmentsPlanningForStructure, getMissionEquipmentsPlanningForStructureKey, removeEquipmentFromMission } from '@/api/missionEquipment';
import { addDays, addHours, endOfMonth, endOfWeek, format, formatISO, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { formatMissionEquipmentsforMissionCalendar, formatMissionEquipmentsforMissionTimeline, parseEquipmentQueryParams } from '@/utils/equipment';
import { StructureSelectHeader } from '@/components/StructureSelectHeader/StructureSelectHeader';
import QueryString from 'qs';
import { RecordId } from '@/interfaces/id';
import MissionDetailForEquipmentModal from '@/screens/MissionDetailForEquipmentModal/MissionDetailForEquipmentModal';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { VehiculesListQueryParams } from '../Vehicules/VehiculesList';
import { NewReservationModal } from './NewReservationModal';
import { CalendarEvent, EquipmentTimeline } from '@/components/EquipmentTimeline/EquipmentTimeline';
import { VehiculeReservationBoxModal } from '@/screens/EquipmentActionButton/VehiculeReservationBox/Modal';
import { useNanuqQuery } from '@/hooks/query';
import { getStructureEquipments, getStructureEquipmentsKey } from '@/api/equipment';
import { getEquipmentHistoriesForStructure } from '@/api/equipmentHistory';


export const ReservationsList = () => {

  const { orgId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast()

  const [selectedEvent, setSelectedEvent] = useState<{ id: RecordId, mission_ids: RecordId[], equipment_id: RecordId } | null>(null)
  const [selectedRange, setSelectedRange] = useState<VehiculesListQueryParams | null>(null)
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<RecordId | null>(null)

  const queryParams = useMemo(() => parseEquipmentQueryParams(searchParams), [searchParams])

  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false })

  const [currentRange, setCurrentRange] = useState<[Date, Date]>([
    queryParams.available_between?.start_date ? parseISO(queryParams.available_between?.start_date) : startOfWeek(new Date(), { weekStartsOn: 1 }),
    queryParams.available_between?.end_date ? parseISO(queryParams.available_between?.end_date) : endOfWeek(new Date(), { weekStartsOn: 1 }),
  ])


  const queryClient = useQueryClient();

  const planningKey = [getMissionEquipmentsPlanningForStructureKey, currentRange, queryParams.structure_id]
  const equipmentPlanningKey = [getMissionEquipmentsPlanningForEquipmentKey, selectedEquipmentId, currentRange]

  const planning = useInfiniteQuery({
    queryKey: [getMissionEquipmentsPlanningForStructureKey, currentRange, queryParams.structure_id, 'infinite'],
    queryFn: ({ pageParam }) => queryParams.structure_id ?
      getMissionEquipmentsPlanningForStructure(queryParams.structure_id, pageParam, { by_period: { started_at: addDays(currentRange[0], -7), ended_at: addDays(currentRange[1], 7) } }) :
      null,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage?.meta?.next
  })

  const equipments = useInfiniteQuery({
    queryKey: [getStructureEquipmentsKey, queryParams.structure_id, 'infinite'],
    queryFn: ({ pageParam }) => queryParams.structure_id ?
      getStructureEquipments(queryParams.structure_id, pageParam, {}) :
      null,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage?.meta?.next
  })

  useEffect(() => {
    if (equipments.hasNextPage && !equipments.isFetching && !equipments.isFetchingNextPage) {
      equipments.fetchNextPage()
    }
  }, [equipments])

  const { mutate: deleteMe, isSuccess, data: deletedMe, reset } = useMutation({
    mutationFn: (missionEquipmentId: RecordId) => removeEquipmentFromMission(missionEquipmentId),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: planningKey })
      await planning.refetch()
    }
  })

  const { mutate: mutateAddEquipmentToMission } = useMutation({
    mutationFn: ({ equipmentId, missionId }: { equipmentId: RecordId, missionId: RecordId }) => (
      addEquipmentToMission(equipmentId, missionId)
    ),
    onError: (err, params, context) => {
      console.log("onError", { err, params, context })
      toast({ title: `Erreur lors de la réservation`, description: err['equipmentId'] || err.message, status: 'error', isClosable: true, duration: 2000 })
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({ queryKey: equipmentPlanningKey })
    }
  })

  const onStructureChange = (structure_id: RecordId | undefined) => {
    setSearchParams(QueryString.stringify({ ...queryParams, structure_id }))
  }

  const onSelectSlot = (start: Date, equipmentId: RecordId) => {
    const params: VehiculesListQueryParams = {
      available_between: { start_date: formatISO(start), end_date: formatISO(addHours(start, 12)) },
    }
    console.log("Slot:", { event, params })
    setSelectedEquipmentId(equipmentId)
    setSelectedRange(params)
  }

  const onSelectEvent = (event: CalendarEvent) => {
    console.log("onSelectEvent", event)
    setSelectedEvent(event as any)
  }

  const onRangeChange = ({ start, end }: { start: Date, end: Date }) => {
    setCurrentRange([start, end])
    console.log('onRangeChange', { start, end }, { currentRange })
  }


  const onCancelEvent = (missionEquipmentId: RecordId) => {
    console.log("onCancelEvent", { missionEquipmentId })
    if (missionEquipmentId) {
      deleteMe(missionEquipmentId)
    }
    setSelectedEvent(null)
  }

  const onNewReservation = async (equipmentId: RecordId, missionId: RecordId) => {
    setSelectedRange(null)
    console.log({ equipmentId, missionId })
    await mutateAddEquipmentToMission({ equipmentId, missionId })
  }

  const onReservationClose = () => {
    setSelectedRange(null)
    setSelectedEquipmentId(null)
  }

  const keys = Object.fromEntries(
    equipments.data?.pages.flatMap(e => e?.data).map(e => ([e?.id || '', e?.attributes.identifier || ''])) || []
  )
  
  return (
    <Box>
      <Box mx={{ base: '0', md: '6' }} px={{ base: '3', md: '0' }} py={3}>
        <HStack>
          <StructureSelectHeader
            flex={1}
            structureId={`${queryParams.structure_id}`}
            onStructureChange={onStructureChange}
          />
        </HStack>
      </Box>
      <BoxSection px={0} bg={'bg.surface'}>
          <VStack spacing={3} alignItems={'stretch'}>
            {/* <EquipmentCalendar
              query={planning}
              formatEvents={formatMissionEquipmentsforMissionCalendar}
              selectedDate={format(currentRange[0], 'yyyy-MM-dd HH:mm')}
              callbacks={{
                onEventClick: onSelectEvent,
                onRangeUpdate: onRangeChange,
                onDoubleClickDate: onSelectSlot,
                onDoubleClickDateTime: onSelectSlot,
              }}
            /> */}
          <EquipmentTimeline
            query={planning}
            formatEvents={formatMissionEquipmentsforMissionTimeline}
            range={currentRange}
            onSelectEvent={onSelectEvent}
            onRangeChange={onRangeChange}
            onSelectSlot={onSelectSlot}
            keys={keys}
          />
          </VStack>
      </BoxSection>
      <MissionDetailForEquipmentModal
        onClose={() => setSelectedEvent(null)}
        isOpen={selectedEvent !== null}
        onCancel={onCancelEvent}
        orgId={orgId}
        size={'xl'}
        equipmentId={selectedEvent?.equipment_id}
        missionIds={selectedEvent?.mission_ids}
      />
      <VehiculeReservationBoxModal
        onClose={onReservationClose}
        isOpen={selectedRange !== null}
        queryParams={selectedRange}
        equipmentId={selectedEquipmentId || ''}
        equipmentName={selectedEquipmentId ? keys[selectedEquipmentId] : undefined}
        onTakeout={onNewReservation}
        onOpen={() => {}}
      />
      {/* <NewReservationModal
        onClose={onReservationClose}
        isOpen={selectedRange !== null}
        params={selectedRange}
      /> */}
    </Box>
  );
}

export default ReservationsList;