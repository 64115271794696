import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Switch,
  IconButton,
  HStack,
  Box,
  Heading,
  FormHelperText,
  Divider,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateMissionAttributes, MissionAttributes, MissionWithEquipmentsAttributes } from '@/interfaces/mission';
import { AsyncSelect } from '@/components/AsyncSelect/AsyncSelect';
import { useNanuqQuery } from '@/hooks/query';
import { updateMission } from '@/api/mission';
import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { getOrganizationStructures, getOrganizationStructuresKey } from '@/api/structure';
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns';
import { getOrganizationEquipmentTypes, getOrganizationEquipmentTypesKey } from '@/api/equipmentType';
import { TbCar, TbMinus, TbPlus, TbSettingsPlus, TbSettingsX, TbUserMinus, TbUserPlus } from 'react-icons/tb';
import { zonedTimeToUtc } from 'date-fns-tz';
import { toLocalDateTimeFormat } from '@/utils/datesFormat';
import { Select } from 'chakra-react-select';
import { MISSION_TYPES_SELECT } from '@/constants/mission';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { getOrganizationUsers, getOrganizationUsersKey } from '@/api/user';
import { UserOrNewField } from '../UserOrNewField/UserOrNewField';
import { getOrganizationEquipments, getOrganizationEquipmentsKey } from '@/api/equipment';
import { EquipmentField } from '../EquipmentField/EquipmentField';

export interface MissionFormProps {
  mission: MissionWithEquipmentsAttributes,
  orgId: RecordId,
  onSave: (newMission: ApiResourceResponse<MissionAttributes>) => Promise<any>
}

const EditMissionForm = ({ mission, orgId, onSave }: MissionFormProps) => {
  const { ability } = useOrganizations()

  const initialValues: CreateMissionAttributes = {
    name: mission?.name || '',
    begin_at: toLocalDateTimeFormat(mission?.begin_at ? parseISO(mission.begin_at) : startOfDay(new Date())),
    end_at: toLocalDateTimeFormat(mission?.end_at ? parseISO(mission.end_at) : endOfDay(new Date())),
    mission_type: mission?.mission_type || '',
    structure_id: mission?.structure_id || -1,
    mission_equipment_requirements_attributes: mission?.requirements ?? [],
    mission_equipments_attributes: mission?.mission_equipments ?? [],
    mission_users_attributes: mission?.mission_users ?? [],
  }


  const equipmentParams = {
    available_between: (mission?.begin_at && mission?.end_at) ? ({
      start_date: formatISO(parseISO(mission?.begin_at)),
      end_date: formatISO(parseISO(mission?.end_at)),
    }) : undefined,
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (missionPayload: CreateMissionAttributes) => updateMission(mission.id, missionPayload)
  })

  const onSubmit = async (
    values: CreateMissionAttributes,
    actions: FormikHelpers<CreateMissionAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newValues: CreateMissionAttributes = {
          ...values,
          begin_at: formatISO(zonedTimeToUtc(values.begin_at, 'Europe/Paris')),
          end_at: formatISO(zonedTimeToUtc(values.end_at, 'Europe/Paris'))
        }

        const newData = await mutateAsync(newValues);
        actions.resetForm()
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  const structureQuery = useNanuqQuery([getOrganizationStructuresKey, orgId], () => getOrganizationStructures(orgId))
  const equipmentQuery = useNanuqQuery([getOrganizationEquipmentsKey, orgId, equipmentParams], () => getOrganizationEquipments(orgId, 1, equipmentParams))
  const equipmentTypeQuery = useNanuqQuery([getOrganizationEquipmentTypesKey, orgId], () => getOrganizationEquipmentTypes(orgId))
  const userQuery = useNanuqQuery([getOrganizationUsersKey, orgId], () => getOrganizationUsers(orgId))

  const additionalEquipmentOptions = mission.mission_equipments.map(m => ({ value: `${m.equipment_id}`, label: m.equipment.name, isDisabled: true }))

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, ...props }) => (
      <Form>
          <ErrorMessage
            name="mission"
            component="div"
            className="field-error"
          />
          <ErrorMessage
            name="structure"
            component="div"
            className="field-error"
          />
        <VStack spacing="3" alignItems={'stretch'}>
          <Box>
            <Heading py={3} size={'xs'}>Général</Heading>
            <VStack spacing="6" alignItems={'stretch'}>
              <HStack>
                <Field name='name'>
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={form.errors.name && form.touched.name ? true : false}>
                      <FormLabel htmlFor='name'>Nom</FormLabel>
                      <Input {...field} required id='name' type='text' placeholder='Nom' />
                      <FormHelperText>L'intitulé de la mission.</FormHelperText>
                      <FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='mission_type'>
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={form.errors.mission_type && form.touched.mission_type ? true : false}>
                      <FormLabel htmlFor='mission_type'>Type</FormLabel>
                      <Select
                        isRequired
                        defaultValue={MISSION_TYPES_SELECT.find(e => e.value === field.value)}
                        isInvalid={form.errors.mission_type ? true : false}
                        placeholder={`Sélectionner`}
                        name={field.name}
                        options={MISSION_TYPES_SELECT}
                        value={MISSION_TYPES_SELECT.find(e => e.value === field.value)}
                        onChange={(opt) => form.setFieldValue(field.name, opt?.value)}
                      />
                      <FormHelperText>Le code analytique de la mission.</FormHelperText>
                      <FormErrorMessage>{form.errors.mission_type as string}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <HStack justifyContent={'space-between'}>
                <Field name='begin_at'>
                  {({ field, form, ...other }: FieldProps) => (
                    <FormControl isInvalid={form.errors.begin_at && form.touched.begin_at ? true : false}>
                      <FormLabel htmlFor='begin_at'>Date de début</FormLabel>
                      <Input {...field} required aria-label="De" type="datetime-local" />
                      <FormHelperText>La date et l'heure de début de la réservation.</FormHelperText>
                      <FormErrorMessage>{form.errors.begin_at as string}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='end_at'>
                  {({ field, form, ...other }: FieldProps) => (
                    <FormControl isInvalid={form.errors.end_at && form.touched.end_at ? true : false}>
                      <FormLabel htmlFor='end_at'>Date de fin</FormLabel>
                      <Input {...field} required aria-label="A" min={form.values.begin_at} type="datetime-local" />
                      <FormHelperText>La date approximative de retour.{' '}</FormHelperText>
                      <FormErrorMessage>{form.errors.end_at as string}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
              <HStack>

                <Field name='structure_id'>
                  {(formProps: FieldProps) => (
                    <AsyncSelect
                      query={structureQuery}
                      label="Structure"
                      helperText="L'unité locale ou la DT mandataire de la mission"
                      {...formProps}
                    />
                  )}
                </Field>
              </HStack>
            </VStack>
          </Box>

{/* 
          {ability?.can('update', 'organization') && <>

            <Heading py={3} size={'xs'}>Matériel</Heading>
            <FieldArray name="mission_equipment_requirements_attributes">
              {({ insert, remove, push }) => (
                <div>
                  {(values.mission_equipment_requirements_attributes?.length || 0) > 0 &&
                    values.mission_equipment_requirements_attributes?.map((mer, index) => (
                      <Box bg={'gray.50'} p={3} my={3} key={index}>
                        <HStack alignItems={'flex-end'}>

                          <Field name={`mission_equipment_requirements_attributes.${index}.equipment_type_id`}>
                            {(formProps: FieldProps) => (
                              <AsyncSelect
                                query={equipmentTypeQuery}
                                label="Type de matériel"
                                {...formProps}
                              />
                            )}
                          </Field>
                          <Field name={`mission_equipment_requirements_attributes.${index}.required`}>
                            {({ field, ...other }: FieldProps) => (
                              <FormControl>
                                <FormLabel
                                  htmlFor={`mission_equipment_requirements_attributes.${index}.required`}
                                >Obligatoire</FormLabel>
                                <Switch {...field} isChecked={field.value} />
                              </FormControl>
                            )}
                          </Field>
                          <Field name={`mission_equipment_requirements_attributes.${index}.count`}>
                            {({ field, ...other }: FieldProps) => (
                              <FormControl>
                                <FormLabel htmlFor={`mission_equipment_requirements_attributes.${index}.count`}>Quantité</FormLabel>
                                <Input type='number' min={0} {...field} />
                                <ErrorMessage
                                  name={`mission_equipment_requirements_attributes.${index}.count`}
                                  component={FormErrorMessage}
                                  className="field-error"
                                />
                              </FormControl>
                            )}
                          </Field>
                          <IconButton
                            aria-label='Supprimer'
                            type="button"
                            variant="secondary"
                            icon={<TbSettingsX />}
                            onClick={() => remove(index)}
                          />
                        </HStack>
                      </Box>
                    ))}
                  <Button
                    type="button"
                    variant="secondary"
                    leftIcon={<TbSettingsPlus />}
                    onClick={() => push({ required: true, mission_id: mission?.id, count: 1 })}
                  >
                    Ajouter du matériel requis
                  </Button>
                </div>
              )}
            </FieldArray>
          </>} */}


          {ability?.can('update', 'mission', mission) && <>
            <Divider />
            <Heading py={3} size={'xs'}>Participants</Heading>
            <FieldArray name="mission_users_attributes">
              {({ insert, remove, push }) => (
                <div>
                  {(values.mission_users_attributes?.length || 0) > 0 &&
                    values.mission_users_attributes?.map((mer, index) => (
                      <Box bg={'gray.50'} p={3} my={3} key={index}>
                        <HStack alignItems={'flex-end'}>

                          <UserOrNewField name={`mission_users_attributes.${index}.user_id`} orgId={orgId} usedIds={values.mission_users_attributes?.map(e => `${e.user_id}`)} />
                          <IconButton
                            aria-label='Supprimer'
                            type="button"
                            variant="secondary"
                            icon={<TbUserMinus />}
                            onClick={() => remove(index)}
                          />
                        </HStack>
                      </Box>
                    ))}
                  <Button
                    type="button"
                    variant="secondary"
                    leftIcon={<TbUserPlus />}
                    onClick={() => push({ mission_id: mission?.id })}
                  >
                    Ajouter un participant
                  </Button>
                </div>
              )}
            </FieldArray>
          </>}


          {ability?.can('update', 'mission', mission) && <>
            <Divider />
            <Heading py={3} size={'xs'}>Équipements</Heading>
            <ErrorMessage
              name="mission_equipments.equipment_id"
              component="div"
              className="field-error"
            />
            <FieldArray name="mission_equipments_attributes">
              {({ insert, remove, push }) => (
                <div>
                  {(values.mission_equipments_attributes?.length || 0) > 0 &&
                    values.mission_equipments_attributes?.map((mer, index) => (
                      <Box bg={'gray.50'} p={3} my={3} key={index}>
                        <HStack alignItems={'flex-end'}>


                          <Field name={`mission_equipments_attributes.${index}.equipment_id`}>
                            {(formProps: FieldProps) => (
                              <EquipmentField
                                name={formProps.field.name}
                                orgId={orgId}
                              />
                            )}
                          </Field>
                          <IconButton
                            aria-label='Supprimer'
                            type="button"
                            variant="secondary"
                            icon={<TbMinus />}
                            onClick={() => remove(index)}
                          />
                        </HStack>
                      </Box>
                    ))}
                  <Button
                    type="button"
                    variant="secondary"
                    leftIcon={<TbPlus />}
                    onClick={() => push({ mission_id: mission?.id })}
                  >
                    Ajouter un équipement
                  </Button>
                </div>
              )}
            </FieldArray>
          </>}

          <Button mt={6}
            variant="primary"
            isLoading={props.isSubmitting}
            disabled={!props.dirty}
            type='submit'
          >
            Mettre à jour
          </Button>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default EditMissionForm