import './styles/timeline.module.css'

import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { MissionEquipmentPlanning, MissionEquipmentPlanningMeta } from '@/interfaces/missionEquipment'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { VStack } from 'rsuite'
import { useEffect, useState } from 'react'
import { addDays, addWeeks, eachDayOfInterval, endOfDay, endOfWeek, format, intervalToDuration, isAfter, isBefore, isSameDay, isToday, isWithinInterval, roundToNearestMinutes, startOfDay, startOfWeek } from 'date-fns'
import { Box, Button, ButtonGroup, FormControl, FormLabel, HStack, Input, Switch, Tooltip } from '@chakra-ui/react'
import styles from './styles/timeline.module.css'
import { groupBy } from '@/utils/object'
import { TbArrowLeft, TbArrowRight, TbPlus } from 'react-icons/tb'
import locale from 'date-fns/locale/fr'
import { cx } from '@/utils/string'
import { colorsForCode } from '@/utils/missions'
import { RecordId } from '@/interfaces/id'
import sortBy from 'lodash.sortby'
import { formatDateShort } from '@/utils/datesFormat'


export interface CalendarEvent {
  start: Date
  end: Date
  id: number
  title: string
  group: string
  mission_id?: number
  equipment_id?: number
  structure_id?: number
  mission_name?: string
  mission_type?: string
  equipment_name?: string
  current_state?: any
}


export interface EquipmentTimelineHeaderProps {
  range: [Date, Date],
}

export interface EquipmentTimelineRowProps {
  range: [Date, Date],
  events: CalendarEvent[],
  onSelectEvent: (event: CalendarEvent) => void
  onSelectSlot: (start: Date, equipmentId: RecordId) => void
  rowtitle: string
  equipmentId: RecordId
}

export interface EquipmentTimelineProps {
  formatEvents: (data: ApiResource<MissionEquipmentPlanning, MissionEquipmentPlanningMeta>[]) => CalendarEvent[],
  query: UseInfiniteQueryResult<InfiniteData<ApiCollectionResponse<MissionEquipmentPlanning, any, MissionEquipmentPlanningMeta> | null, unknown>, Error>
  onSelectEvent: (event: CalendarEvent) => void
  onRangeChange: ({ start, end }: { start: Date, end: Date }) => void
  onSelectSlot: (start: Date, equipmentId: RecordId) => void
  range: [Date, Date],
  keys: { [k: string]: string; }
}

export function EquipmentTimelineHeader({
  range
}: EquipmentTimelineHeaderProps) {

  const eventsPerDay = eachDayOfInterval({ start: range[0], end: range[1] })

  return <Box className={styles.timelineRowHeader}>
    <Box className={styles.timelineRowTitle}>
    </Box>
    {eventsPerDay.flatMap((date, i) => {
      const today = isToday(date)
      return (
        <Box key={date.toISOString()} className={cx([styles.timelineRowHeaderItem, today && styles.timelineRowHeaderToday])}>
          {format(date, 'E dd MMM', { locale })}
        </Box>
      )
    })}
  </Box>
}

export const generateAllEventsForDate = (date: Date, events: CalendarEvent[], rowtitle: string) => {

  const generateVoidEvent = (id: any, start: Date, end: Date): CalendarEvent => {
    return {
      start,
      end,
      id,
      title: '',
      group: rowtitle
    }
  }

  const range = { start: date, end: addDays(date, 1) }
  const dateEvents = events.filter(e => isWithinInterval(e.start, range) || isWithinInterval(e.end, range))

  const fullEvents = dateEvents.length == 0 ? [generateVoidEvent(`${date.toISOString()}-void-0`, date, endOfDay(date))] : dateEvents.flatMap((event, index) => {

    event.start = roundToNearestMinutes(event.start, { nearestTo: 30 })
    event.end = roundToNearestMinutes(event.end, { nearestTo: 30 })
    if (!isSameDay(event.start, date)) {
      event.start = startOfDay(date)
    }
    if (!isSameDay(event.end, date)) {
      event.end = endOfDay(date)
    }

    const isFirst = (index == 0)
    const isLast = index == (dateEvents.length - 1)

    if (isFirst && isAfter(event.start, date) && isLast && isBefore(event.end, endOfDay(date))) {
      return [
        generateVoidEvent(`${date.toISOString()}-void-${index}-b`, date, event.start),
        event,
        generateVoidEvent(`${date.toISOString()}-void-${index}-c`, event.end, endOfDay(date)),
      ]
    } else if (isFirst && isAfter(event.start, date)) {
      return [
        event,
        generateVoidEvent(`${date.toISOString()}-void-${index}`, date, event.start),
      ]
    } else if (isLast && isBefore(event.end, endOfDay(date))) {
      return [
        event,
        generateVoidEvent(`${date.toISOString()}-void-${index}`, event.end, endOfDay(date)),
      ]
    } else if (!isFirst && isBefore(dateEvents[index - 1].end, event.start)) {
      return [
        generateVoidEvent(`${date.toISOString()}-void-${index}`, dateEvents[index - 1].end, event.start),
        event
      ]
    } else {
      return [event]
    }
  })


  const eventsWithBoundaries = sortBy(fullEvents, 'start').map(event => {

    const startBefore = isBefore(event.start, range.start)
    const endsAfter = isAfter(event.end, range.end)
    const interval = {
      start: startBefore ? date : event.start,
      end: endsAfter ? endOfDay(date) : event.end
    }
    const durationFull = intervalToDuration(interval)

    const durationHours = (durationFull.hours || 0) + ((durationFull.minutes || 0) / 60)

    return {
      event,
      startBefore,
      endsAfter,
      interval,
      durationHours
    }
  });

  return eventsWithBoundaries
}

export function CompactEquipmentTimelineRow({
  range,
  events,
  onSelectEvent,
  onSelectSlot,
  rowtitle,
  equipmentId,
}: EquipmentTimelineRowProps) {

  const eventsPerDay = eachDayOfInterval({ start: range[0], end: range[1] })


  return <Box className={styles.timelineRow}>
    <Box title={rowtitle} className={styles.timelineRowCompactTitle} noOfLines={1}>
      {rowtitle}
    </Box>
    {eventsPerDay.flatMap((date, i) => {
      
      const eventsWithBoundaries = generateAllEventsForDate(date, events, rowtitle)

      return (
        <Box key={date.toISOString()} className={styles.timelineRowCompactItems}>
          {eventsWithBoundaries.map(({ interval, durationHours, event }) => (
            <Tooltip
              key={`${date.toISOString()}-${event.id}`}
              label={event.title == '' ? '+ Réserver' : <Box>{event.title}: {format(event.start, 'HH:mm')}-{format(event.end, 'HH:mm')}</Box>}
              placement='top'>
              <Box
                cursor={'pointer'}
                onClick={() => event.title == '' ? onSelectSlot(interval.start, equipmentId) : onSelectEvent(event)}
                className={cx([styles.timelineRowCompactItem, event.title == '' ? styles.timelineRowCompactVoidItem : ''])}
                width={`${(durationHours / 24) * 100}%`}
              >
                {event.title == '' ? (
                  <Box className={cx([styles.timelineRowCompactVoidInnerItem])} width={'100%'}></Box>
                ) : (
                  <Box
                    {...colorsForCode(event.mission_type)}
                    className={cx([styles.timelineRowCompactInnerItem])}
                    width={'100%'}
                  >{event.title}</Box>
                )}
              </Box>
            </Tooltip>
          ))}
        </Box>
      )
    })}
  </Box>
}

export function EquipmentTimelineRow({
  range,
  events,
  onSelectEvent,
  onSelectSlot,
  rowtitle,
  equipmentId,
}: EquipmentTimelineRowProps) {

  const eventsPerDay = eachDayOfInterval({ start: range[0], end: range[1] })

  return <Box className={styles.timelineRow}>
    <Box className={styles.timelineRowTitle}>
      {rowtitle}
    </Box>
    {eventsPerDay.flatMap((date, i) => {
      const range = { start: date, end: addDays(date, 1) }
      const dateEvents = events.filter(e => isWithinInterval(e.start, range) || isWithinInterval(e.end, range))
      const eventsWithBoundaries = sortBy(dateEvents, 'start').map(event => {
        return {
          event,
          startBefore: isBefore(event.start, range.start),
          endsAfter: isAfter(event.end, range.end),
        }
      })

      const lastevent = eventsWithBoundaries.length > 0 ? eventsWithBoundaries[eventsWithBoundaries.length - 1] : null
      const maxStart = lastevent?.endsAfter ? date : (lastevent?.event?.end ?? date)

      return (
        <Box key={date.toISOString()} className={styles.timelineRowItems}>
          {eventsWithBoundaries.map(({ event, startBefore, endsAfter }) => (
            <Box
              cursor={'pointer'}
              onClick={() => onSelectEvent(event)}
              className={styles.timelineRowItem}
              key={`${date.toISOString()}-${event.id}`}
              {...colorsForCode(event.mission_type)}
            >
              <Box className={styles.timelineRowItemTitle}>{event.title}</Box>
              <Box className={styles.timelineRowItemDate}>{startBefore ? '00:00' : format(event.start, 'HH:mm')} - {endsAfter ? '00:00' : format(event.end, 'HH:mm')}</Box>
            </Box>
          ))}
          <Box cursor={'pointer'} onClick={() => onSelectSlot(maxStart, equipmentId)} className={styles.timelineRowItemGhost} key={`${date.toISOString()}-add`}>
            <TbPlus />
            <Box className={styles.timelineRowItemTitle}>Réserver</Box>
          </Box>

        </Box>
      )
    })}
  </Box>
}


export function EquipmentTimeline({
  formatEvents,
  query,
  onSelectEvent,
  onRangeChange,
  onSelectSlot,
  range,
  keys,
}: EquipmentTimelineProps) {

  

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } = query

  useEffect(() => {
    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetching, isFetchingNextPage, fetchNextPage])

  const filteredData: CalendarEvent[] = formatEvents(data?.pages?.flatMap(e => e?.data ?? []) ?? [])

  const rows = {
    ...Object.fromEntries(filteredData.map(e => [e.equipment_id, e.group])),
    ...keys
  }
  const grouped = groupBy(filteredData, e => e.equipment_id || '')

  const [isCompact, setCompact] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")

  const onPrevious = () => {
    onRangeChange({ start: addWeeks(range[0], -1), end: addWeeks(range[1], -1) })
  }

  const onNext = () => {
    onRangeChange({ start: addWeeks(range[0], 1), end: addWeeks(range[1], 1) })
  }

  const onToday = () => {
    onRangeChange({ start: startOfWeek(new Date(), { weekStartsOn: 1 }), end: endOfWeek(new Date(), { weekStartsOn: 1 }) })
  }

  const elements = sortBy(
      Object.keys(rows), [o => o, function (o) { return (grouped[o] || []).length > 0 }]
  ).filter(e => search.length == 0 || rows[e].toLocaleLowerCase().includes(search.toLocaleLowerCase()))

  const RowElement = isCompact ? CompactEquipmentTimelineRow : EquipmentTimelineRow

  return <VStack spacing={0} className={styles.timelineRoot}>
    <HStack justifyContent={'space-between'} px={3} pb={3} mb={3} w={'100%'} borderBottom={'1px solid'} borderColor={'bg.muted'}>
      <HStack>
        <ButtonGroup size={'sm'} isAttached>
          <Button leftIcon={<TbArrowLeft />} onClick={onPrevious}>Précédent</Button>
          <Button onClick={onToday}>Aujourd'hui</Button>
          <Button rightIcon={<TbArrowRight />} onClick={onNext}>Suivant</Button>
        </ButtonGroup>
      </HStack>
      <HStack>
        <Input type='search' size={'sm'} placeholder='Rechercher' onChange={(e) => setSearch(e.target.value)}/>
      </HStack>
      <HStack>
        <FormControl as={HStack}>
          <FormLabel htmlFor='isChecked'>Vue compacte</FormLabel>
          <Switch id='isChecked' isChecked={isCompact} onChange={e => setCompact(e.target.checked)}/>
        </FormControl>
      </HStack>
    </HStack>
    <EquipmentTimelineHeader range={range} />
    {elements.flatMap(e => (
      <RowElement
        key={e}
        events={grouped[e] || []}
        equipmentId={e}
        range={range}
        rowtitle={rows[e]}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
      />
    ))}
  </VStack>
}