import {
  background,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ChakraComponent,
  Heading,
  HStack,
  position,
  Skeleton,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { ReactNode } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { useLazyImage } from '@/hooks/useLazyImage';
import Marker from '../Marker/Marker';

export interface BreadcrumbItemProps {
  label: string | ReactNode,
  url: string,
}
export interface PageWithTitleProps {
  children?: ReactNode,
  title?: string | ReactNode,
  titleRaw?: string | ReactNode,
  subtitle?: string,
  image?: string | null,
  placeholderImage?: string | null,
  isLoading?: boolean,
  mainAction?: ReactNode
  actions?: ReactNode | ReactNode[]
  subHeader?: ReactNode | ReactNode[]
  breadcrumbs?: (BreadcrumbItemProps | null)[]
  stackProps?: StackProps
}

export const PageWithTitle = ({
  title,
  titleRaw,
  subtitle,
  children,
  subHeader,
  actions,
  mainAction,
  image,
  placeholderImage,
  stackProps = {},
  breadcrumbs = [],
  isLoading = false,
}: PageWithTitleProps) => {

  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false })
  const titleSize = useBreakpointValue({ base: 'sm', lg: 'sm' })

  const { imageUrl, isImageLoaded } = useLazyImage(image, placeholderImage)

  const skeletonProps = {
    startColor:'gray.700',
    endColor:'gray.500',
    width: '100%'
  }

  const filtered = breadcrumbs.filter(e => e !== null) as BreadcrumbItemProps[]

  const breadcrumbMobileAttributes = (!isMobile || !image) ? {} : {
    backgroundColor: 'bg.surface',
    color: 'fg.default',
    borderRadius: 3,
    py: 1,
    px: 3
  }

  const breadCrumbsElement = breadcrumbs && breadcrumbs.length && (
    <Breadcrumb
      py={3}
      {...breadcrumbMobileAttributes}
      listProps={{ display: 'flex' }}
      spacing={{ base: '1', lg: '2' }}
      separator={<ChevronRightIcon
      display={'flex'}
      color='gray.500'
      />}
    >
      {filtered.map((b: BreadcrumbItemProps) => (<BreadcrumbItem key={b.url}>
        <BreadcrumbLink as={Link} to={b.url}><Text noOfLines={1}>{b.label}</Text></BreadcrumbLink>
      </BreadcrumbItem>))}
    </Breadcrumb>
  )

  const mobileAttributes = !isMobile || !image ? {} : {
    bgImage: `url(${imageUrl})`,
    backgroundPosition : "center center",
    backgroundSize : "cover",
    minH: '30vh'
  }

  const mobileMainButton: any = !isMobile ? {} : {
    position: 'absolute',
    left: 0,
    right: 0,
    px: 3,
    zIndex: 100,
    display: 'flex',
    bottom: '70px'
  }

  return (
    <>
      <VStack
        justify="flex-start"
        alignContent={"flex-start"}
        alignItems={'stretch'}
        pt={{ base: '3', lg: '6' }}
        flex={"1 1 100%"}
        {...mobileAttributes}
      >
        <Stack flex={"1 1 100%"} px={{ base: '0', md: '3', lg: '3' }} spacing={{ base: '3', lg: '6' }}>
          <Stack
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
            justify="space-between"
            align={{ base: 'start', lg: 'center' }}
            alignItems={'stretch'}
            flex={"1 1 100%"}
            px={3}
            py={3}
          >
            <Stack spacing="1" flex={"1 1 100%"} justifyContent={['space-between', 'space-between']}>
              {breadCrumbsElement}
              {titleRaw ? (<>{titleRaw}</>) : title && <Heading size={titleSize} fontWeight="bold">
                {title}
              </Heading>}
              {subtitle && <Skeleton height={'30px'} {...skeletonProps} isLoaded={!isLoading}>
                <Text color="muted">{subtitle}</Text>
              </Skeleton>}
            </Stack>
            <HStack spacing="3" alignItems={['flex-end', 'flex-start']} justifyContent={'flex-end'}>
              <Box {...mobileMainButton}>{mainAction}</Box>
              {actions}
            </HStack>
          </Stack>
        </Stack>
        {subHeader && <Stack py={0} spacing={{ base: '0', lg: '0' }}>
            {subHeader}
        </Stack>}
      </VStack>
      <Stack pb={6} spacing={{ base: '3', lg: '6' }} {...stackProps}>
        {children}
      </Stack>
    </>
  );
}
